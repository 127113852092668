export const Star = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      style={{ isolation: "isolate" }}
      viewBox="0 0 40 40"
      width="20pt"
      height="20pt"
    >
      <defs>
        <clipPath id="_clipPath_2EtlnAkhVKiWeRJapxI5dsirGz4rCOxg">
          <rect width="40" height="40" />
        </clipPath>
      </defs>
      <g clipPath="url(#_clipPath_2EtlnAkhVKiWeRJapxI5dsirGz4rCOxg)">
        <path
          d=" M 31.756 38.09 L 20 31.91 L 8.244 38.09 L 10.489 25 L 0.979 15.729 L 14.122 13.82 L 20 1.91 L 25.878 13.82 L 39.021 15.729 L 29.511 25 L 31.756 38.09 Z "
          fill={color}
        />
      </g>
    </svg>
  );
};
