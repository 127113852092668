import { logEvent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";
import { analytics } from "../linker";

export const Tile = (props: any) => {
  const nav = useNavigate();

  return (
    <div
      className="HoverIcon"
      style={{
        height: "10vh",
        width: "10vh",
        minHeight: "50px",
        minWidth: "50px",
        borderRadius: "10px",
        backgroundColor: "rgba(100,100,100,.2)",
        margin: "5px",
        color: "rgb(220,220,220)",
      }}
      onClick={() => {
        logEvent(analytics, "select_item", { button: props.address });
        nav(props.address);
      }}
    >
      <img src={props.imageSrc} width={"100%"} height={"100%"} alt="TileIcon" />
    </div>
  );
};
