import { Image } from "antd";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "../linker";

type SlideShow = { currentSlide: number; slideSet: Array<string> };

export const Slides = () => {
  const [slideState, setSlideState] = useState<{
    id: string;
    show: SlideShow;
  } | null>(null);

  const slidesEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  useEffect(() => {
    setSlideState(null);
    onSnapshot(collection(db, "slides"), (snapshot) =>
      snapshot.docs.forEach((doc) =>
        setSlideState(() => {
          return { id: doc.id, show: doc.data() as SlideShow };
        })
      )
    );
    console.log(slideState);
  }, []);

  useEffect(() => {
    slidesEl.current && slidesEl.current.requestFullscreen();
  }, []);

  const fullScr = () => {
    slidesEl.current && slidesEl.current.requestFullscreen();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "clip",
      }}
    >
      <div ref={slidesEl} style={{ width: "100vw", height: "100vh" }}>
        <Image
          src={slideState?.show.slideSet[slideState.show.currentSlide]}
          width="100%"
          preview={false}
          onLoad={() => fullScr()}
        />
      </div>
    </div>
  );
};
