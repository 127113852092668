import { Button, Form, Input, message } from "antd";
import { addDoc, collection } from "firebase/firestore";
import { useRecoilState } from "recoil";
import { db } from "../linker";
import { questionState } from "../recoil";
import homeIcon from "./../Home/Home.png";
import { Tile } from "../Tile/Tile";

export const Questions = () => {
  const [questionForm] = Form.useForm();
  const [questionvalues, setQuestionValues] = useRecoilState(questionState);

  const handleQuestion = () => {
    questionForm
      .validateFields()
      .then((values) => {
        addDoc(collection(db, "questions"), {
          ...values,
          mod: false,
          answered: false,
          date: new Date().getTime(),
        }).then(() => message.success("Thanks, we got it."));
      })
      .then(() => {
        setQuestionValues({ name: questionvalues.name, question: null });
        questionForm.resetFields(["question"]);
      })
      .catch((err) => {
        console.log(err);
        if (err.values.question === null) {
          message.error("Did you forget to enter a question?");
        } else {
          message.error("Failed to submit, try again.");
        }
      });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ color: "white", alignSelf: "center", paddingTop: "2rem" }}>
        Ask a Question
      </div>
      <div style={{ width: "90%", maxWidth: "400px", marginTop: "1rem" }}>
        <Form
          form={questionForm}
          initialValues={questionvalues}
          onChange={() => setQuestionValues(questionForm.getFieldsValue())}
        >
          <Form.Item name={"name"}>
            <Input placeholder="First name only" />
          </Form.Item>
          <Form.Item
            name={"question"}
            rules={[{ required: true, message: "You must ask a question." }]}
          >
            <Input.TextArea
              placeholder="Write your question here..."
              rows={10}
            />
          </Form.Item>
        </Form>
      </div>
      <Button
        type="default"
        style={{ marginTop: "1rem" }}
        onClick={() => handleQuestion()}
      >
        Submit
      </Button>
      <div style={{ position: "absolute", bottom: "30px" }}>
        <Tile imageSrc={homeIcon} address="/" />
      </div>
    </div>
  );
};
