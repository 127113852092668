import { AimOutlined, BugOutlined } from "@ant-design/icons";
import { Button, Popconfirm, ConfigProvider, theme, message } from "antd";
import { collection, onSnapshot, updateDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import Map, { Marker, Source, Layer } from "react-map-gl";
import { useNavigate } from "react-router-dom";
import { db } from "../linker";
import { GameDataType, TeamDataType, TeamInfoType } from "../types";
import { Star } from "./Star";
import "mapbox-gl/dist/mapbox-gl.css";
import type { SkyLayer } from "react-map-gl";
import { TeamDrawer } from "./Team/TeamDrawer";
import { TeamLogin } from "./Team/TeamLogin";
import { TeamContention } from "./Team/TeamContention";
import { ClueDrawer } from "./Clue/ClueDrawer";
import { Ping, Pong } from "./Ping/Ping";

require("mapbox-gl/dist/mapbox-gl.css");

const skyLayer: SkyLayer = {
  id: "sky",
  type: "sky",
  paint: {
    "sky-type": "atmosphere",
    "sky-atmosphere-sun": [0.0, 0.0],
    "sky-atmosphere-sun-intensity": 15,
  },
};

export const QRCode = () => {
  const [codeResult] = useState<null | string>(null);
  const [gameData, setGameData] = useState<Array<GameDataType>>([]);
  const [teamData, setTeamData] = useState<Array<TeamDataType>>([]);
  const [teamInfo, setTeamInfo] = useState<null | TeamInfoType>(null);

  const nav = useNavigate();

  useEffect(() => {
    const getData = onSnapshot(collection(db, "game"), (snapshot) => {
      setGameData([]);
      snapshot.forEach((snapDoc) => {
        if (!snapDoc.data().isDeleted) {
          setGameData((prev) => [
            ...prev,
            { ...snapDoc.data(), id: snapDoc.id } as GameDataType,
          ]);
        }
      });
    });

    return getData;
  }, []);

  useEffect(() => {
    const getTeamData = onSnapshot(collection(db, `teams`), (snapshot) => {
      setTeamData([]);
      snapshot.forEach((snapDoc) => {
        let score = 0;
        snapDoc.data().teamObjectivesComplete.forEach((item: GameDataType) => {
          score = score + item.points;
        });
        setTeamData((prev) => [
          ...prev,
          { ...snapDoc.data(), teamScore: score } as TeamDataType,
        ]);
      });
    });

    return getTeamData;
  }, []);

  useEffect(() => {
    if (teamInfo?.name === "GodMode") return;
    const unsubscribe = onSnapshot(collection(db, `ping`), (snapshot) => {
      snapshot.forEach((snapDoc) => {
        if (snapDoc.data().teamName !== "GodMode") return;
        // fetch user location and report.
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            updateDoc(doc(db, `ping/${teamInfo?.name}`), {
              teamName: teamInfo?.name,
              coordinates: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            } as Pong);
          },
          (err) => {
            // message.error("Failed to get your location: " + err.message);
          }
        );
      });
    });

    return unsubscribe;
  }, [teamInfo?.name]);

  return (
    <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          maxWidth: "100vw",
          maxHeight: "100vh",
          objectFit: "cover",
          overflow: "hidden",
        }}
      >
        <Map
          mapLib={import("mapbox-gl")}
          initialViewState={{
            longitude: 172.620722,
            latitude: -43.527148,
            zoom: 15,
          }}
          style={{
            height: "100vh",
            width: "100vw",
            position: "fixed",
            top: "0px",
            left: "0px",
            zIndex: "20",
          }}
          mapStyle="mapbox://styles/mapbox/satellite-v9" //"mapbox://styles/philipshaw/clle23cs700gq01rdchgp1ayk"
          mapboxAccessToken="pk.eyJ1IjoicGhpbGlwc2hhdyIsImEiOiJja3A2dWFiZG0wdDh6MnFrdmFvY3V0OXVyIn0.7fRKRYwIEe89NDmKfZhWng"
          projection={{ name: "globe" }}
          terrain={{ source: "mapbox-dem", exaggeration: 1.5 }}
        >
          <Source
            id="mapbox-dem"
            type="raster-dem"
            url="mapbox://mapbox.mapbox-terrain-dem-v1"
            tileSize={512}
            maxzoom={14}
          />
          <Layer {...skyLayer} />

          {teamInfo?.name === "GodMode" &&
            gameData.map((objective) => {
              return (
                <Marker
                  key={objective.id}
                  longitude={objective.coordinates.lng}
                  latitude={objective.coordinates.lat}
                  onClick={() => {
                    console.log(objective);
                  }}
                >
                  <div
                    style={{
                      color: "gold",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      left: "50%",
                      top: "50%",
                    }}
                  >
                    <AimOutlined rev />
                  </div>
                </Marker>
              );
            })}

          {teamInfo?.name === "GodMode" && <Ping />}

          {teamData.map((team, i) => {
            return team.teamObjectivesComplete.map((item, idx) => {
              return (
                <Marker
                  key={idx}
                  longitude={item.coordinates.lng}
                  latitude={item.coordinates.lat}
                >
                  <div
                    style={{
                      color: team.color,
                      display: "flex",
                      flexDirection: "column",
                      width: "50px",
                      position: "relative",
                      left: `${i * 34 - (teamData.length * 34) / 2}px`,
                    }}
                  >
                    <Star color={team.color} />
                    <div>{team.teamName}</div>
                  </div>
                </Marker>
              );
            });
          })}
        </Map>
        <div
          style={{
            width: "100vw",
            position: "fixed",
            top: "0px",
            left: "0px",
            zIndex: "25",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "rgba(40,40,40,9)",
              padding: "1rem",
            }}
          >
            <div style={{ fontWeight: "bold", color: "rgb(47,98,142)" }}>
              The HUNT
            </div>
            {teamInfo && <h4 style={{ color: "white" }}>{teamInfo.name}</h4>}
            <Popconfirm
              title="Reset Team?"
              onConfirm={() => {
                window.localStorage.removeItem("teamName");
                window.localStorage.removeItem("teamPassCode");
                nav("/");
              }}
            >
              <Button danger>
                <BugOutlined rev />
              </Button>
            </Popconfirm>
          </div>
          {codeResult ? (
            <div
              style={{
                backgroundColor:
                  codeResult === "Wrong answer... Try again"
                    ? "rgba(255,100,100,.8)"
                    : "rgba(100,255,100,.8)",
                padding: "1rem",
              }}
            >
              {codeResult}
            </div>
          ) : (
            <div
              style={{ backgroundColor: "rgba(10,10,10,.9)", padding: "1rem" }}
            >
              <TeamContention teamData={teamData} />
            </div>
          )}
        </div>

        <ClueDrawer
          teamData={teamData}
          gameData={gameData}
          teamInfo={teamInfo}
        />
        <TeamDrawer teamData={teamData} />

        <TeamLogin setTeamInfo={setTeamInfo} teamData={teamData} />
      </div>
    </ConfigProvider>
  );
};
