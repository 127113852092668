import { Button, Form, Input, message } from "antd";
import { addDoc, collection } from "firebase/firestore";
import { useEffect } from "react";
import { analytics, db } from "../linker";
import { Tile } from "../Tile/Tile";
import homeIcon from "./../Home/Home.png";
import { logEvent } from "firebase/analytics";

export const Contact = () => {
  const [contactForm] = Form.useForm();

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  useEffect(() => {
    logEvent(analytics, "contact", { Location: "contact" });
  }, []);

  const handleContactForm = () => {
    contactForm
      .validateFields()
      .then((values: any) => {
        addDoc(collection(db, "messages"), {
          name: values.name,
          email: values.email,
          phone: values.phone,
          message: values.message,
          date: new Date().getTime(),
        })
          .then(() => {
            message.success("Message received");
          })
          .catch((err) => {
            console.error(err);
            message.error("Failed to receive message.");
          });
      })
      .then(() => contactForm.resetFields())
      .catch((err) => {
        console.error(err);
        message.error("Failed to save message.");
      });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: "#9F9F9F",
          padding: "1rem",
          borderBottom: "1px solid rgb(40,40,40)",
          borderBottomColor: "rgb(100,180,255)",
          fontSize: "2rem",
          marginBottom: "2rem",
        }}
      >
        Contact
      </div>
      <div
        style={{
          marginBottom: "1rem",
          color: "#9F9F9F",
          width: "min(600px, 90%)",
        }}
      >
        If you would like to contact me, feel free to use this form to get in
        touch and I will endeavour to reply as soon as I can.
      </div>
      <div
        style={{
          marginBottom: "1rem",
          color: "#9F9F9F",
          width: "min(600px, 90%)",
        }}
      >
        All of the fields are required. Please include the reason for making
        contact in your message.
      </div>
      <div
        style={{
          marginBottom: "1rem",
          color: "#9F9F9F",
          width: "min(600px, 90%)",
        }}
      >
        Thanks,
      </div>
      <div
        style={{
          marginBottom: "1rem",
          color: "#9F9F9F",
          width: "min(600px, 90%)",
        }}
      >
        Phil
      </div>
      <div
        style={{
          padding: "1rem",
          width: "90%",
          maxWidth: "600px",
        }}
      >
        <Form form={contactForm}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Your name is required to complete this form.",
              },
            ]}
          >
            <Input placeholder="Name" required />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Your name contact email address is required.",
              },
              {
                type: "email",
                message: "It appears that this is not a valid email address.",
              },
            ]}
          >
            <Input placeholder="Email address" name="email" type="email" />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: "Your phone number is required." },
              { max: 15, message: "This phone number appears to be too long." },
            ]}
          >
            <Input placeholder="Phone number" />
          </Form.Item>
          <Form.Item
            name="message"
            rules={[{ required: true, message: "Please include a message." }]}
          >
            <Input.TextArea placeholder="Message" name="message" required />
          </Form.Item>
        </Form>

        <Button size="small" type="primary" onClick={() => handleContactForm()}>
          Submit
        </Button>
      </div>
      <div style={{ position: "absolute", bottom: "30px" }}>
        <Tile imageSrc={homeIcon} address="/" />
      </div>
    </div>
  );
};
