import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./../AuthProvider/AuthProvider";

export function SecureRoute({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.currentUser) {
    return (
      <Navigate
        to={`/`} //${location.pathname}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}
