import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider/AuthProvider";
import { Tile } from "../Tile/Tile";
import homeIcon from "./../Home/Home.png";
import { useEffect } from "react";

export const Tools = () => {
  const goTo = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    console.log(auth);
  }, [auth]);

  const buttons = [
    { label: "Results", path: "/results" },
    { label: "Experiment", path: "/experiment" },
    { label: "Messages", path: "/messages" },
    { label: "Slide Controller", path: "/slide-controller" },
    { label: "Slides", path: "/slides" },
    { label: "Notes", path: "/notes" },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignContent: "center",
          width: "90%",
          maxWidth: "200px",
          paddingTop: "2rem",
        }}
      >
        {buttons.map((item, idx) => {
          return (
            <Button
              key={idx}
              style={{ marginBottom: ".25rem" }}
              onClick={() => goTo(item.path)}
            >
              {item.label}
            </Button>
          );
        })}
        <Button type="primary" ghost onClick={() => auth.logout()}>
          Logout
        </Button>
        <div
          style={{
            position: "absolute",
            bottom: "30px",
          }}
        >
          <Tile imageSrc={homeIcon} address="/" />
        </div>
      </div>
    </div>
  );
};
