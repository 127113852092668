import {
  FirestoreError,
  collection,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../linker";
import { GameDataType, TeamDataType } from "../types";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Progress,
  message,
} from "antd";
import { nanoid } from "nanoid";
import { AimOutlined, SyncOutlined } from "@ant-design/icons";
import { PDFPrint } from "./Team/PDFPrint";

export const GamePlanner = () => {
  const [gameDataForm] = Form.useForm();
  const [teamForm] = Form.useForm();
  const [latLngUpdateForm] = Form.useForm();
  const [gameData, setGameData] = useState<Array<GameDataType>>([]);
  const [teamData, setTeamData] = useState<Array<TeamDataType>>([]);

  useEffect(() => {
    const games = onSnapshot(collection(db, "game"), (snapshot) => {
      setGameData([]);
      snapshot.forEach((snapDoc) => {
        setGameData((prev) => [
          ...prev,
          { ...snapDoc.data(), id: snapDoc.id } as GameDataType,
        ]);
      });
    });
    return games;
  }, []);

  useEffect(() => {
    const teams = onSnapshot(collection(db, "teams"), (snapshot) => {
      setTeamData([]);
      snapshot.forEach((teamDoc) => {
        let score = 0;
        teamDoc.data().teamObjectivesComplete.forEach((item: GameDataType) => {
          score = score + item.points;
        });
        setTeamData((prev) => [
          ...prev,
          {
            ...teamDoc.data(),
            teamScore: score,
          } as TeamDataType,
        ]);
      });
    });
    return teams;
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          color: "white",
          width: "95%",
          maxWidth: "500px",
          paddingBottom: "3rem",
        }}
      >
        <h2>Teams</h2>
        <div>
          <h4>New Team</h4>
          <Form form={teamForm}>
            <Form.Item name="name">
              <Input placeholder="Team name" />
            </Form.Item>
            <Form.Item name="passcode">
              <Input placeholder="Team passcode" />
            </Form.Item>
            <Form.Item name="color">
              <Input placeholder="Color" />
            </Form.Item>
          </Form>
          <Button
            onClick={() => {
              teamForm
                .validateFields()
                .then((values) => {
                  setDoc(doc(db, `teams/${values.name}`), {
                    isDeleted: false,
                    teamName: values.name,
                    teamObjectivesComplete: [],
                    passcode: values.passcode,
                    color: values.color,
                    deductions: 0,
                  } as TeamDataType).catch((err) => {
                    console.error(err);
                    message.error("Failed to save");
                  });
                })
                .then(() => {
                  message.success("Team saved");
                })
                .catch((err) => {
                  console.error(err);
                  message.error("Failed to save");
                });
            }}
          >
            Save Team
          </Button>
        </div>
        <div style={{ paddingTop: "1rem" }}>
          {teamData
            .filter((team) => !team.isDeleted)
            .map((item, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    border: "1px solid rgb(50,50,50)",
                    padding: "1rem",
                    borderRadius: ".3rem",
                    fontSize: ".75rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: ".9rem" }}>
                      <b>{item.teamName}</b>
                    </div>
                    <Popconfirm
                      title="Delete?"
                      onConfirm={() => {
                        updateDoc(doc(db, `teams/${item.teamName}`), {
                          isDeleted: true,
                        });
                      }}
                    >
                      <Button danger size="small">
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                  <div>{item.passcode}</div>
                  <div style={{ marginTop: "1rem" }}>
                    <div style={{ marginBottom: ".5rem" }}>
                      <b>Completed Objectives</b>
                    </div>
                    <Progress
                      percent={
                        (item.teamObjectivesComplete.length /
                          gameData.filter((item2) => !item2.isDeleted).length) *
                        100
                      }
                    />
                    {item.teamObjectivesComplete.map((obj, idx2) => {
                      return (
                        <div
                          key={idx2}
                          style={{
                            color: "rgb(255, 100,100)",
                            padding: ".25rem",
                            backgroundColor: "rgb(30,30,30)",
                            marginBottom: ".25rem",
                          }}
                        >
                          {obj.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div style={{ color: "white", width: "95%", maxWidth: "500px" }}>
        <h2>Objectives</h2>
        <h4>New Objective</h4>
        {
          //   instruction: string;
        }
        <Form form={gameDataForm}>
          <Form.Item
            name="description"
            rules={[{ required: true, message: "This is required" }]}
          >
            <Input.TextArea rows={5} placeholder="Objective clue" />
          </Form.Item>
          <Form.Item
            name="hintOne"
            rules={[{ required: true, message: "This is required" }]}
          >
            <Input.TextArea rows={2} placeholder="Hint 1" />
          </Form.Item>
          <Form.Item
            name="hintTwo"
            rules={[{ required: true, message: "This is required" }]}
          >
            <Input.TextArea rows={2} placeholder="Hint 2" />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[{ required: true, message: "This is required" }]}
          >
            <Input placeholder="Required answer" />
          </Form.Item>
          <Form.Item
            name="points"
            rules={[{ required: true, message: "This is required" }]}
          >
            <InputNumber placeholder="Points" />
          </Form.Item>
          <Form.Item
            name="lat"
            rules={[{ required: true, message: "This is required" }]}
          >
            <Input placeholder="Latitude" allowClear />
          </Form.Item>
          <Form.Item
            name="lng"
            rules={[{ required: true, message: "This is required" }]}
          >
            <Input placeholder="Longitude" allowClear />
          </Form.Item>
        </Form>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() =>
              navigator.geolocation.getCurrentPosition((pos) => {
                gameDataForm.setFieldValue("lat", pos.coords.latitude);
                gameDataForm.setFieldValue("lng", pos.coords.longitude);
              })
            }
            icon={<AimOutlined rev />}
          />
          <Button
            danger
            onClick={() => gameDataForm.resetFields()}
            icon={<SyncOutlined rev />}
          />
          <Button
            onClick={() => {
              gameDataForm
                .validateFields()
                .then((values) => {
                  const newObjId = nanoid();
                  setDoc(doc(db, `game/${newObjId}`), {
                    code: values.code,
                    coordinates: { lat: values.lat, lng: values.lng },
                    description: values.description,
                    instruction: "Well done. Keep going.",
                    hintOne: values.hintOne,
                    hintTwo: values.hintTwo,
                    isDeleted: false,
                    points: values.points,
                    created: new Date().getTime(),
                  } as GameDataType)
                    .then(() => message.success("Added new objective"))
                    .catch((err) => {
                      console.error(err);
                      message.error("Failed to save");
                    });
                })
                .catch((err) => {
                  console.error(err);
                  message.error("Failed to save");
                });
            }}
          >
            Save Objective
          </Button>
        </div>

        <h4>Objectives</h4>
        <PDFPrint data={gameData} />
        <div style={{ paddingBottom: "5rem" }}>
          {gameData.map((item, idx) => {
            console.log({ item });
            return (
              <div
                key={idx}
                style={{
                  border: "1px solid rgb(50,50,50)",
                  padding: "1rem",
                  borderRadius: ".3rem",
                  fontSize: ".75rem",
                  color: item.isDeleted ? "rgb(150,50,50)" : "whitesmoke",
                  marginBottom: ".5rem",
                }}
              >
                <div>{`Clue: ${item.description}`}</div>
                {item.hintOne && <div>{`Hint one: ${item.hintOne}`}</div>}
                {item.hintTwo && <div>{`Hint two: ${item.hintTwo}`}</div>}
                <div>{`Points: ${item.points}`}</div>
                <div>{`Answer: ${item.code}`}</div>
                <div style={{ paddingTop: "1rem" }}>
                  {!item.isDeleted ? (
                    <Popconfirm
                      title="Delete?"
                      onConfirm={() => {
                        if (!item.id) return;
                        updateDoc(doc(db, `game/${item.id}`), {
                          isDeleted: true,
                        }).catch((err: FirestoreError) =>
                          message.error(err.message)
                        );
                      }}
                    >
                      <Button size="small" danger ghost>
                        Delete
                      </Button>
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      title="Reinstate?"
                      onConfirm={() => {
                        if (!item.id) return;
                        updateDoc(doc(db, `game/${item.id}`), {
                          isDeleted: false,
                        }).catch((err: FirestoreError) =>
                          message.error(err.message)
                        );
                      }}
                    >
                      <Button size="small" ghost>
                        Reinstate
                      </Button>
                    </Popconfirm>
                  )}
                </div>
                <div style={{ paddingTop: "1rem" }}>
                  <Popconfirm
                    title="Update?"
                    onConfirm={() => {
                      if (!item.id) return;
                      latLngUpdateForm
                        .validateFields()
                        .then((values) => {
                          updateDoc(doc(db, `game/${item.id}`), {
                            coordinates: { lat: values.lat, lng: values.lng },
                          }).catch((err: FirestoreError) =>
                            message.error(err.message)
                          );
                        })
                        .catch((err) => message.error("Failed to update"));
                    }}
                  >
                    <Button size="small" ghost>
                      Update Loc
                    </Button>
                  </Popconfirm>
                  <Form form={latLngUpdateForm}>
                    <Form.Item
                      name="lat"
                      rules={[{ required: true, message: "This is required" }]}
                    >
                      <Input placeholder="Latitude -43.5" allowClear />
                    </Form.Item>
                    <Form.Item
                      name="lng"
                      rules={[{ required: true, message: "This is required" }]}
                    >
                      <Input placeholder="Longitude 172.5" allowClear />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
