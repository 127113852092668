import { Form, Input, message } from "antd";
import type { TeamDataType, GameDataType } from "../../types";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../linker";

type ClueProps = {
  item: GameDataType;
  teamName: string;
  completedObjectives: GameDataType[];
};

export const Clue = (props: ClueProps) => {
  const { item, teamName, completedObjectives } = props;

  const [codeForm] = Form.useForm();

  return (
    <Form form={codeForm}>
      <Form.Item name="code">
        <Input.Search
          placeholder="Answer..."
          onSearch={(answer: string, event) => {
            if (!answer.trim()) return;

            if (item.code.toLowerCase() === answer.toLowerCase()) {
              message.success(item.instruction);
              codeForm.resetFields();
              updateDoc(doc(db, `teams/${teamName}`), {
                teamObjectivesComplete: [...completedObjectives, item],
              } as TeamDataType);
            } else {
              message.error("Wrong answer... Try again");
            }
          }}
          enterButton="Check"
          allowClear
        />
      </Form.Item>
    </Form>
  );
};
