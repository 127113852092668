import { AimOutlined, WifiOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { useEffect, useState, useRef } from "react";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../linker";
import { Marker } from "react-map-gl";

export type Pong = {
  teamName: string;
  coordinates: {
    lat: number;
    lng: number;
  };
};

export const Ping = () => {
  const [pongs, setPongs] = useState<Pong[]>([]);

  const timer = useRef<number>();

  useEffect(() => {
    const getTeamData = onSnapshot(collection(db, `ping`), (snapshot) => {
      snapshot.forEach((snapDoc) => {
        const data = snapDoc.data();
        if (data.teamName === "GodMode") return;
        setPongs((prev) => [...prev, { ...data } as Pong]);
      });
      clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        setPongs([]);
      }, 6000);
    });

    return getTeamData;
  }, []);

  return (
    <>
      <Button
        icon={<WifiOutlined rev />}
        style={{
          position: "fixed",
          bottom: "2rem",
          right: "2rem",
          zIndex: 3000,
        }}
        onClick={() => {
          updateDoc(doc(db, `ping/GodMode`), {
            teamName: "GodMode",
            coordinates: {
              lat: Math.random() * 90,
              lng: Math.random() * 90,
            },
          } as Pong)
            .then(() => {
              message.info("Ping Sent");
            })
            .catch((err) => {
              message.error("Error sending ping: " + err.message);
            });
        }}
      >
        Trigger Ping
      </Button>

      {pongs.map((pong) => (
        <Marker
          key={pong.teamName}
          longitude={pong.coordinates.lng}
          latitude={pong.coordinates.lat}
          onClick={() => {
            console.log(pong);
          }}
        >
          <div
            style={{
              color: "gold",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              transform: "scale(1.5)",
              animation: "ping 500ms infinite",
            }}
          >
            <AimOutlined rev />
          </div>
        </Marker>
      ))}
    </>
  );
};
