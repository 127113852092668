import { Modal, Form, Input, message } from "antd";
import { useState, useEffect } from "react";
import type { Dispatch, SetStateAction } from "react";
import type { TeamInfoType, TeamDataType } from "../../types";

type TeamLoginProps = {
  setTeamInfo: Dispatch<SetStateAction<TeamInfoType | null>>;
  teamData: TeamDataType[];
};

export const TeamLogin = (props: TeamLoginProps) => {
  const { setTeamInfo, teamData } = props;

  const [isModal, setIsModal] = useState(true);

  const [teamForm] = Form.useForm();

  useEffect(() => {
    const passcode = localStorage.getItem("teamPassCode");
    const name = localStorage.getItem("teamName");
    if (!name && !passcode) return;
    setTeamInfo({ passcode: passcode, name: name } as TeamInfoType);
    setIsModal(false);
  }, [setTeamInfo]);

  const submit = () => {
    teamForm.validateFields().then((values) => {
      if (
        teamData.filter(
          (team) =>
            team.passcode === values.passcode && team.teamName === values.name
        ).length > 0
      ) {
        localStorage.setItem("teamPassCode", values.passcode);
        localStorage.setItem("teamName", values.name);
        setTeamInfo({ name: values.name, passcode: values.passcode });
        setIsModal(false);
      } else {
        message.error("Invalid team name or passcode");
      }
    });
  };

  return (
    <Modal open={isModal} onOk={submit}>
      <div>Team Name</div>
      <Form form={teamForm}>
        <Form.Item name="name">
          <Input placeholder="Team name" />
        </Form.Item>
        <Form.Item name="passcode">
          <Input placeholder="Team pass code" onPressEnter={submit} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
