import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../linker";

type Message = {
  date: number;
  email: string;
  message: string;
  name: string;
  phone: string;
};

export const Messages = () => {
  const [messages, setMessages] = useState<Array<Message>>([]);
  useEffect(() => {
    const unsub = onSnapshot(collection(db, "messages"), (snapshot) => {
      setMessages([]);
      snapshot.forEach((doc) => {
        setMessages((prev) => [...prev, doc.data() as Message]);
      });
    });
    return unsub;
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignContent: "center",
          width: "90%",
          maxWidth: "500px",
          paddingTop: "2rem",
        }}
      >
        {messages.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                color: "whitesmoke",
                padding: ".5rem",
                border: "1px solid rgb(80,80,100)",
                borderRadius: ".5rem",
                marginBottom: ".25rem",
                fontSize: ".7rem",
                width: "100%",
              }}
            >
              <div style={{ fontSize: ".7rem", paddingBottom: ".5rem" }}>
                {item.name}
              </div>
              <div
                style={{ color: "rgb(200,200,200)", paddingBottom: ".5rem" }}
              >
                {item.message}
              </div>
              <div style={{ color: "rgb(255,100,100)" }}>{item.phone}</div>
              <div style={{ color: "rgb(100,150,255)" }}>{item.email}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
