import {
  collection,
  doc,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../linker";
import { nanoid } from "nanoid";
import { Button, Form, Input, InputNumber, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";

type TagsResult = {
  complete: boolean;
  date: number;
  id: string;
  query: { text: string; numberOfTags: number };
  result: string;
};

export const Generate = () => {
  const [speechForm] = Form.useForm();
  const [imageForm] = Form.useForm();
  const [tagForm] = Form.useForm();

  const [tagList, setTagList] = useState<Array<any>>([]);

  const handleSpeechForm = () => {
    speechForm
      .validateFields()
      .then((values) => {
        const newId = nanoid();
        const speechRef = doc(db, `speech/${newId}`);
        setDoc(speechRef, {
          date: new Date().getTime(),
          query: values.speech,
          complete: false,
          id: newId,
        })
          .then(() => message.success("Sent to server."))
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const handleImageForm = () => {
    imageForm
      .validateFields()
      .then((values: { image: string }) => {
        const newId = nanoid();
        const imageRef = doc(db, `image/${newId}`);
        setDoc(imageRef, {
          date: new Date().getTime(),
          query: values.image,
          complete: false,
          id: newId,
        })
          .then(() => message.success("Sent to server."))
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const handleTagForm = () => {
    tagForm
      .validateFields()
      .then((values: { text: string; numberOfTags: number }) => {
        const newId = nanoid();
        const tagRef = doc(db, `tags/${newId}`);
        setDoc(tagRef, {
          date: new Date().getTime(),
          query: values,
          complete: false,
          id: newId,
        })
          .then(() => message.success("Sent to server."))
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    const tagQuery = query(
      collection(db, "tags"),
      orderBy("date"),
      limitToLast(2)
    );
    onSnapshot(
      tagQuery,
      (snap) => {
        setTagList([]);
        snap.forEach((snapDoc) => {
          if (!snapDoc.exists) return;
          setTagList((prev) => [...prev, snapDoc.data()]);
        });
      },
      (err) => {
        console.error("Failed to save tag request");
      }
    );
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignContent: "center",
          width: "100%",
          maxWidth: "400px",
          paddingTop: "2rem",
        }}
      >
        <div style={{ padding: "1rem" }}>
          <div style={{ color: "white" }}>Speech</div>
          <Form form={speechForm}>
            <FormItem name="speech">
              <Input.TextArea placeholder="Text to speech" rows={3} />
            </FormItem>
          </Form>
          <Button ghost type="primary" onClick={() => handleSpeechForm()}>
            Generate Speech
          </Button>
        </div>
        <div style={{ padding: "1rem" }}>
          <div style={{ color: "white" }}>Image</div>
          <Form form={imageForm}>
            <FormItem name="image">
              <Input.TextArea placeholder="Image prompt" rows={3} />
            </FormItem>
          </Form>
          <Button ghost type="primary" onClick={() => handleImageForm()}>
            Generate Image
          </Button>
        </div>
        <div style={{ padding: "1rem" }}>
          <div style={{ color: "white" }}>Tags</div>
          <Form form={tagForm}>
            <FormItem name="text">
              <Input.TextArea placeholder="Image prompt" rows={3} />
            </FormItem>
            <FormItem name="numberOfTags">
              <InputNumber />
            </FormItem>
          </Form>
          <Button ghost type="primary" onClick={() => handleTagForm()}>
            Generate Tags
          </Button>
          {tagList.map((tagResult: TagsResult, idx) => {
            return (
              <div key={idx}>
                <div
                  style={{
                    color: "white",
                    fontSize: ".75rem",
                    backgroundColor: "rgb(30,30,30)",
                    padding: "1rem",
                    borderRadius: ".5rem",
                    marginTop: ".5rem",
                  }}
                >
                  {tagResult.result}
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: ".75rem",
                    backgroundColor: "rgb(30,30,30)",
                    padding: "1rem",
                    borderRadius: ".5rem",
                    marginTop: ".5rem",
                  }}
                >
                  {tagResult.result.replace(/#/g, ",").replace(" ", "")}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
