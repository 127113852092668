import {
  AudioOutlined,
  CaretLeftOutlined,
  CheckSquareOutlined,
  EditOutlined,
  FieldTimeOutlined,
  PauseCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  message,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import {
  collection,
  doc,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { db } from "../linker";
import { projectState } from "../recoil";
import { Project } from "../types";
import dayjs from "dayjs";
type Note = { note: string; date: number; deleted: boolean; id?: string };
type Hour = {
  timeStart: number;
  timeEnd?: number;
  deleted: boolean;
  id: string;
  isEnded: boolean;
  project: string;
  description?: string;
};
const { RangePicker } = DatePicker;

export const Notes = () => {
  const [noteForm] = Form.useForm();
  const [jobForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [isModal, setIsModal] = useState(false);
  const [notes, setNotes] = useState<Array<Note>>([]);
  const [recording, setRecording] = useState(false);
  const [result, setResult] = useState<string | null>(null);
  const [isPro, setIsPro] = useState(true);
  const [selectedPro, setSelectedPro] = useState<null | string>(null);
  const [hours, setHours] = useState<Array<Hour>>([]);
  const [timeNow, setTimeNow] = useState(new Date().getTime());
  const [isEdit, setIsEdit] = useState(false);
  const projects = useRecoilValue(projectState);
  const [record, setRecord] = useState<Hour | null>(null);

  useEffect(() => {
    const setTimer = setInterval(() => {
      if (hours.filter((hour) => !hour.isEnded).length >= 1) {
        setTimeNow(() => new Date().getTime());
      }
    }, 1000);
    return () => clearInterval(setTimer);
  }, [hours]);

  useEffect(() => {
    if (!selectedPro) return;

    const qu = query(
      collection(db, `hours`),
      orderBy("timeStart"),
      limitToLast(6)
    );
    const unsub = onSnapshot(qu, (snapshot) => {
      setHours([]);
      snapshot.forEach((doc) => {
        setHours((prev) => [...prev, { ...doc.data(), id: doc.id } as Hour]);
      });
    });
    return unsub;
  }, [selectedPro]);

  useEffect(() => {
    if (!selectedPro) return;

    const qu = query(
      collection(db, `projects/${selectedPro}/notes`),
      orderBy("date"),
      limitToLast(30)
    );
    const unsub = onSnapshot(qu, (snapshot) => {
      setNotes([]);
      snapshot.forEach((doc) => {
        setNotes((prev) => [...prev, { ...doc.data(), id: doc.id } as Note]);
      });
    });
    return unsub;
  }, [selectedPro]);

  const recognition = useRef(
    new (window.SpeechRecognition || window.webkitSpeechRecognition)()
  );

  useEffect(() => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      recognition.current.continuous = false;

      recognition.current.lang = "en-US";

      recognition.current.onresult = (event) => {
        if (result !== null) {
          setResult((prev) => {
            noteForm.setFieldValue(
              "note",
              prev + " " + event.results[0][0].transcript
            );
            return prev + " " + event.results[0][0].transcript;
          });
        } else {
          setResult(event.results[0][0].transcript);
          noteForm.setFieldValue("note", event.results[0][0].transcript);
        }
      };

      recognition.current.onerror = (event) => {
        message.error(`Speech recognition error: ${event.error}`);
      };
    } else {
      message.error("Unsupported");
    }
  }, [result, noteForm]);

  const startEngine = () => {
    recognition.current.start();
  };

  const stopEngine = () => {
    recognition.current.stop();
  };

  const handleSave = () => {
    noteForm.validateFields().then((values) => {
      if (isPro) {
        const newId = nanoid();
        setDoc(doc(db, `/projects/${newId}`), {
          title: values.note,
          isActive: true,
          date: new Date().getTime(),
          id: newId,
        } as Project)
          .then(() => {
            message.success("Saved");
            noteForm.resetFields();
          })
          .catch((err) => console.error(err));
      } else {
        const newId = nanoid();
        setDoc(doc(db, `projects/${selectedPro}/notes/${newId}`), {
          ...values,
          deleted: false,
          date: new Date().getTime(),
        })
          .then(() => {
            message.success("Saved");
            noteForm.resetFields();
          })
          .catch((err) => console.error(err));
      }
    });
  };

  const handleDelete = (id: string) => {
    updateDoc(doc(db, `projects/${selectedPro}/notes/${id}`), {
      deleted: true,
    }).catch((err) => console.error(err));
  };

  const handleComplete = (id: string) => {
    updateDoc(doc(db, `projects/${id}`), {
      isActive: false,
    }).catch((err) => console.error(err));
  };

  const handleTimeStart = () => {
    const newId = nanoid();
    setDoc(doc(db, `hours/${newId}`), {
      timeStart: new Date().getTime(),
      deleted: false,
      project: selectedPro,
      isEnded: false,
      id: newId,
    } as Hour)
      .then(() => setIsModal(false))
      .catch((err) => console.error(err));
  };

  const handleJobForm = (id: string) => {
    jobForm
      .validateFields()
      .then((values) => {
        updateDoc(doc(db, `hours/${id}`), {
          timeEnd: new Date().getTime(),
          isEnded: true,
          description: values.description,
        } as Hour);
      })
      .then(() => {
        message.success("Saved");
        setIsModal(false);
      })
      .catch((err) => console.error(err));
  };

  const handleEditForm = (id: string) => {
    editForm
      .validateFields()
      .then((values) => {
        updateDoc(doc(db, `hours/${id}`), {
          timeStart: values.dates[0].toDate().getTime(),
          timeEnd: values.dates[1].toDate().getTime(),
          description: values.description,
        } as Hour);
      })
      .then(() => {
        message.success("Saved");
        setRecord(null);
        setIsModal(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignContent: "center",
          width: "100%",
          maxWidth: "400px",
          paddingTop: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            alignContent: "center",
            width: "100%",
            height: "70vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {isPro && (
            <div
              style={{
                color: "whitesmoke",
                padding: ".5rem",
              }}
            >
              Hours
            </div>
          )}
          {hours
            .filter((hour) => hour.isEnded)
            .map((hour, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    color: "rgb(50,200,90)",
                    padding: ".5rem",
                    border: "1px solid rgb(80,80,100)",
                    borderRadius: ".5rem",
                    marginBottom: ".25rem",
                    fontSize: ".7rem",
                    width: "90%",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div>{`On Clock for ${projects
                      ?.filter((project) => project.id === hour.project)
                      .map((project, idx) => {
                        return project.title;
                      })}`}</div>
                    {hour.timeEnd && (
                      <div>{`Duration: ${Math.round(
                        (hour.timeEnd - hour.timeStart) / 60000
                      )} minutes`}</div>
                    )}
                  </div>
                  <div>
                    <Button
                      ghost
                      type="text"
                      style={{ color: "gold" }}
                      icon={<EditOutlined rev="true" />}
                      onClick={() => {
                        setRecord(() => {
                          editForm.setFieldsValue({
                            description: hour.description,
                            dates: hour.timeEnd
                              ? [dayjs(hour.timeStart), dayjs(hour.timeEnd)]
                              : [null, null],
                          });

                          return { ...hour };
                        });
                        setIsModal(true);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          {hours
            .filter((hour) => !hour.isEnded)
            .map((hour, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    color: "rgb(255,50,50)",
                    padding: ".5rem",
                    border: "1px solid rgb(80,80,100)",
                    borderRadius: ".5rem",
                    marginBottom: ".25rem",
                    fontSize: ".7rem",
                    width: "90%",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div>{`On Clock for ${projects
                      ?.filter((project) => project.id === hour.project)
                      .map((project, idx) => {
                        return project.title;
                      })}`}</div>
                    <div>{`Elapsed: ${Math.round(
                      (timeNow - hour.timeStart) / 60000
                    )} minutes`}</div>
                  </div>
                  <div>
                    <Button
                      ghost
                      type="text"
                      style={{ color: "rgb(255,50,50)" }}
                      icon={<CheckSquareOutlined rev="true" />}
                      onClick={() => {
                        setRecord(() => {
                          return { ...hour } as Hour;
                        });
                        setIsModal(true);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          {!isPro && (
            <div
              style={{
                padding: ".5rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "whitesmoke" }}>
                {projects?.filter((pro) => pro.id === selectedPro)[0].title}
              </div>
              <Button
                type="text"
                style={{ color: "whitesmoke" }}
                onClick={() => {
                  setIsPro(true);
                }}
                icon={<CaretLeftOutlined rev="true" />}
              >
                To Projects
              </Button>
            </div>
          )}
          {!isPro &&
            notes
              .filter((item) => !item.deleted)
              .map((note, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      color: "whitesmoke",
                      padding: ".5rem",
                      border: "1px solid rgb(80,80,100)",
                      borderRadius: ".5rem",
                      marginBottom: ".25rem",
                      fontSize: ".7rem",
                      width: "90%",
                      alignSelf: "center",
                    }}
                  >
                    <div style={{ color: "rgb(255,100,100)" }}>
                      {new Date(note.date).toDateString()}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignSelf: "center",
                        justifyContent: "space-between",
                        color: "whitesmoke",
                      }}
                    >
                      <div style={{ color: "whitesmoke" }}>{note.note}</div>
                      <Popconfirm
                        title="Delete?"
                        onConfirm={() =>
                          note.id
                            ? handleDelete(note.id)
                            : message.error("No Id")
                        }
                      >
                        <Button
                          ghost
                          type="text"
                          style={{ color: "whitesmoke" }}
                          icon={<CheckSquareOutlined rev />}
                        />
                      </Popconfirm>
                    </div>
                  </div>
                );
              })}
          {isPro && (
            <div style={{ color: "whitesmoke", padding: ".5rem" }}>
              Projects
            </div>
          )}
          {isPro &&
            projects &&
            projects?.map((pro, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    color: "whitesmoke",
                    padding: ".5rem",
                    border: "1px solid rgb(80,80,100)",
                    borderRadius: ".5rem",
                    marginBottom: ".25rem",
                    fontSize: ".7rem",
                    width: "90%",
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                      justifyContent: "space-between",
                      color: "whitesmoke",
                    }}
                  >
                    <div>
                      <div style={{ color: "rgb(255,100,100)" }}>
                        {new Date(pro.date).toDateString()}
                      </div>
                      <div style={{ color: "rgb(100,150,255)" }}>
                        {pro.title}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "30%",
                        justifyContent: "space-evenly",
                        color: "whitesmoke",
                      }}
                    >
                      <Popover title="Finish and delete this task">
                        <Popconfirm
                          title="Complete"
                          onConfirm={() =>
                            pro.id
                              ? handleComplete(pro.id)
                              : message.error("No Id")
                          }
                        >
                          <Button
                            ghost
                            style={{ color: "rgb(255,100,100)" }}
                            type="text"
                            icon={<CheckSquareOutlined rev />}
                          />
                        </Popconfirm>
                      </Popover>
                      <Popover title="Start the job clock">
                        <Button
                          ghost
                          style={{ color: "rgb(50,200,90)" }}
                          type="text"
                          onClick={() => {
                            setSelectedPro(pro.id);
                            setIsModal(true);
                            // setIsPro(false);
                          }}
                          icon={<FieldTimeOutlined rev />}
                        />
                      </Popover>
                      <Popover title="Project tasks and notes">
                        <Button
                          ghost
                          style={{ color: "gold" }}
                          type="text"
                          onClick={() => {
                            setSelectedPro(pro.id);
                            setIsPro(false);
                          }}
                          icon={<UnorderedListOutlined rev="true" />}
                        />
                      </Popover>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          style={{
            color: "whitesmoke",
            padding: ".5rem",
            border: "1px solid rgb(80,80,100)",
            borderRadius: ".5rem",
            marginBottom: ".25rem",
            fontSize: ".7rem",
            width: "90%",
            alignSelf: "center",
            marginTop: "1rem",
            paddingTop: "1rem",
          }}
        >
          <div>
            <Form form={noteForm} initialValues={{ note: null }}>
              <FormItem name="note">
                <Input.TextArea rows={3} />
              </FormItem>
            </Form>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Button onClick={() => handleSave()}>Save</Button>
            <Button
              ghost
              onClick={() => {
                if (recording) {
                  setRecording(false);
                  stopEngine();
                } else {
                  setRecording(true);
                  startEngine();
                }
              }}
            >
              {recording ? (
                <PauseCircleOutlined
                  style={{ color: "rgb(255,100,100)" }}
                  rev
                />
              ) : (
                <AudioOutlined style={{ color: "rgb(100,255,100)" }} rev />
              )}
            </Button>
            <Button
              onClick={() => {
                setResult(null);
                noteForm.resetFields();
              }}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={isModal}
        onCancel={() => {
          editForm.resetFields();
          setRecord(null);
          setIsModal(false);
        }}
        footer={null}
        okText="Start"
      >
        {hours.filter((hour) => !hour.isEnded).length >= 1 ? (
          hours
            .filter((hour) => !hour.isEnded)
            .map((hour, idx) => {
              return (
                <div>
                  <div>{`Project:  ${projects
                    ?.filter((project) => project.id === hour.project)
                    .map((project, idx) => {
                      return project.title;
                    })}`}</div>

                  <div>{`Start time: ${
                    new Date(hour.timeStart).toTimeString().split(" GMT")[0]
                  }`}</div>

                  <Form form={jobForm}>
                    <Form.Item name="description">
                      <Input.TextArea placeholder="Description" />
                    </Form.Item>
                  </Form>

                  <Button onClick={() => handleJobForm(hour.id)}>Finish</Button>
                </div>
              );
            })
        ) : (
          <>
            {record ? (
              <div>{`Project:  ${projects
                ?.filter((project) => project.id === record.project)
                .map((project, idx) => {
                  return project.title;
                })}`}</div>
            ) : (
              <div>{`Project:  ${projects
                ?.filter((project) => project.id === selectedPro)
                .map((project, idx) => {
                  return project.title;
                })}`}</div>
            )}
            {!record && (
              <div>{`Start time: ${
                new Date().toTimeString().split(" GMT")[0]
              }`}</div>
            )}
            {record && (
              <Form form={editForm}>
                <Form.Item name="dates">
                  <RangePicker showTime />
                </Form.Item>
                <Form.Item name="description">
                  <Input.TextArea placeholder="Description" />
                </Form.Item>
              </Form>
            )}
            {record ? (
              <Button onClick={() => handleEditForm(record.id)}>Update</Button>
            ) : (
              <Button onClick={() => handleTimeStart()}>Start</Button>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};
