import {
  collection,
  collectionGroup,
  doc,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, store } from "../linker";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Popover,
  Select,
  Upload,
  UploadProps,
  message,
} from "antd";
import { nanoid } from "nanoid";
import { stopCoverage } from "v8";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { RcFile } from "antd/es/upload";
import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  DownSquareOutlined,
  PlusOutlined,
  RightSquareOutlined,
  TagOutlined,
} from "@ant-design/icons";

type Questions = {
  id: string;
  question: string;
  answer?: string;
  complete: boolean;
  created: number;
  pdfId: string;
};

type Tag = {
  pos: number;
  label: string;
};

type PDFResult = {
  id: string;
  pdfUrl: string;
  pdfText?: Array<string>;
  analysis?: Array<Questions>;
  created: number;
  title: string;
  summary: string;
  tags: Array<Tag>;
  deleted: false;
  extracted: boolean;
};

export const PDFAnalysis = () => {
  const [pdfForm] = Form.useForm();
  const [questionForm] = Form.useForm();
  const [PDFList, setPDFList] = useState<Array<PDFResult>>([]);
  const [pdfName, setPdfName] = useState<null | string>(null);
  const [newFile, setNewFile] = useState<RcFile | null>(null);
  const [isDisable, setIsDisabled] = useState(true);
  const [activePDF, setActivePDF] = useState<null | PDFResult>(null);
  const [gptResults, setGptResults] = useState<Array<Questions>>([]);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    const pdfQuery = query(
      collection(db, "pdf"),
      orderBy("created"),
      limitToLast(10)
    );
    const unsub = onSnapshot(
      pdfQuery,
      (snap) => {
        setPDFList([]);
        snap.forEach((snapDoc) => {
          if (!snapDoc.exists) return;
          setPDFList((prev) => [...prev, snapDoc.data() as PDFResult]);
        });
      },
      (err) => {
        console.error("Failed to save tag request");
      }
    );

    return unsub;
  }, []);

  useEffect(() => {
    const gptQuery = query(
      collectionGroup(db, `analysis`),
      orderBy("created"),
      limitToLast(10)
    );
    const unsub = onSnapshot(
      collection(db, `pdf/${activePDF?.id}/analysis`),
      (snap) => {
        setGptResults([]);
        snap.forEach((snapDoc) => {
          if (!snapDoc.exists) return;
          setGptResults((prev) => [...prev, snapDoc.data() as Questions]);
        });
      },
      (err) => {
        console.error("Failed to listen to results", err);
      }
    );

    return unsub;
  }, [activePDF?.id]);

  const handleSaveNew = async (url: string) => {
    const newId = nanoid();

    return await pdfForm
      .validateFields()
      .then((values) => {
        setDoc(doc(db, `pdf/${newId}`), {
          ...values,
          id: newId,
          created: new Date().getTime(),
          pdfUrl: url,
          deleted: false,
          extracted: false,
          tags: [],
        } as PDFResult).then(() => {
          return true;
        });
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const handleQuestionForm = () => {
    questionForm.validateFields().then((values) => {
      const newId = nanoid();
      setDoc(doc(db, `pdf/${activePDF?.id}/analysis/${newId}`), {
        complete: false,
        created: new Date().getTime(),
        id: newId,
        question: `${values.question}: ${values.text}`,
        pdfId: activePDF?.id,
      } as Questions);
    });
  };

  const props: UploadProps = {
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";

      if (!isPDF) {
        message.error(`${file.name} is not a pdf file`);
      } else {
        setPdfName(file.name);
        setNewFile(file);
      }

      return isPDF;
    },
    accept: "application/pdf",
    customRequest: () => {
      if (newFile) {
        console.log(newFile);
        const storeRef = ref(store, `pdf/${newFile.name}`);
        uploadBytes(storeRef, newFile)
          .then((res) => {
            getDownloadURL(storeRef)
              .then((url) => {
                return handleSaveNew(url);
              })
              .then((val) =>
                val ? message.success("Saved") : message.error("Failed")
              )
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      }
    },
    disabled: isDisable,
    showUploadList: false,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        color: "black",
        overflowY: "hidden",
        alignContent: "space-evenly",
      }}
    >
      <div style={{ width: "45%", padding: "3%", overflowY: "scroll" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: ".25rem",
          }}
        >
          <div>PDF Upload</div>{" "}
          <Button
            icon={<PlusOutlined rev="true" />}
            onClick={() => setIsModal(true)}
          />
        </div>
        {PDFList.map((pdf, idx) => {
          return (
            <Card
              style={{
                backgroundColor: "rgb(240,240,240)",
                border: "1px solid rgb(220,220,230)",
                marginBottom: ".5rem",
                fontSize: ".75rem",
              }}
              key={idx}
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{pdf.title}</div>
                  <div>
                    <Button
                      ghost
                      style={{
                        color: pdf.id === activePDF?.id ? "red" : "dodgerblue",
                      }}
                      type={"default"}
                      onClick={() =>
                        activePDF?.id === pdf.id
                          ? setActivePDF(null)
                          : setActivePDF(pdf)
                      }
                    >
                      {pdf.id !== activePDF?.id ? (
                        <DownSquareOutlined rev="true" />
                      ) : (
                        <CloseSquareOutlined rev="true" />
                      )}
                    </Button>
                  </div>
                </div>
              }
            >
              <div>{pdf.summary}</div>
              {activePDF && activePDF?.id === pdf.id && (
                <div>
                  {pdf.pdfText ? (
                    <div>
                      {pdf.pdfText.map((item, idx) => {
                        return (
                          <div
                            key={idx}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {/* <Popover
                              title="Add tags"
                              content={
                                <div style={{ width: "95%" }}>
                                  <Select
                                    style={{ width: "150px" }}
                                    defaultValue={pdf.tags
                                      ?.filter((tag) => tag.pos === idx)
                                      .map((tag) => tag.label)}
                                    mode="tags"
                                    onChange={(value) => {
                                      console.log(value, idx);
                                      if (value.length > 0) {
                                        updateDoc(doc(db, `pdf/${pdf.id}`), {
                                          tags: [
                                            ...pdf.tags,
                                            ...value.map((tagLabel) => {
                                              return {
                                                label: tagLabel,
                                                pos: idx,
                                              } as Tag;
                                            }),
                                          ],
                                        });
                                      }
                                    }}
                                  >
                                    <Select.Option value={"Conflict"}>
                                      Conflict
                                    </Select.Option>
                                    <Select.Option value={"Question"}>
                                      Question
                                    </Select.Option>
                                  </Select>
                                </div>
                              }
                            >
                              <Button
                                size="small"
                                type="text"
                                icon={<RightSquareOutlined rev="true" />}
                                onClick={() => {}}
                              />
                            </Popover> */}
                            {/* {pdf.tags
                              ?.filter((tag) => tag.pos === idx)
                              .map((tag, ind) => {
                                return (
                                  <div style={{ color: "red" }} key={ind}>
                                    *
                                  </div>
                                );
                              })} */}
                            <div>{item}</div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    "Not yet extracted"
                  )}
                </div>
              )}
            </Card>
          );
        })}
      </div>
      <div
        style={{
          width: "45%",
          height: "100vh",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "34%",
            padding: "3%",
            overflowY: "scroll",
          }}
        >
          <div style={{ paddingBottom: ".5rem", paddingTop: "1rem" }}>
            Textual Analysis
          </div>
          <Form form={questionForm}>
            <Form.Item
              name="text"
              rules={[
                {
                  required: true,
                  message: "You must provide the text to be analysed.",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Text block for analysis"
                rows={6}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="question"
              rules={[
                {
                  required: true,
                  message: "A question regarding the text is required.",
                },
              ]}
            >
              <Input.TextArea placeholder="Question" allowClear />
            </Form.Item>

            <Button type="primary" onClick={() => handleQuestionForm()}>
              Get Analysis
            </Button>
          </Form>{" "}
        </div>
        <div
          style={{
            width: "100%",
            height: "55%",
            padding: "3%",
          }}
        >
          <div style={{ paddingBottom: ".5rem", paddingTop: "1rem" }}>
            Textual Analysis Results
          </div>
          <div
            style={{
              paddingTop: "1rem",
              overflowY: "scroll",
              height: "90%",
              width: "100%",
            }}
          >
            {" "}
            {gptResults.map((item, idx) => {
              return (
                <div style={{ width: "100%" }} key={idx}>
                  <div style={{ color: "rgb(10,80,255)", fontSize: ".75rem" }}>
                    {item.question}
                  </div>
                  <div
                    style={{
                      fontSize: ".75rem",
                      paddingTop: ".5rem",
                      paddingBottom: "2rem",
                    }}
                  >
                    {item.answer}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        title="PDF Upload"
        open={isModal}
        onCancel={() => setIsModal(false)}
        footer={null}
      >
        <Form
          form={pdfForm}
          onChange={() =>
            pdfForm
              .validateFields()
              .then(() => {
                setIsDisabled(false);
              })
              .catch(() => setIsDisabled(true))
          }
        >
          <Form.Item
            name="title"
            rules={[{ required: true, message: "This is required to upload" }]}
          >
            <Input placeholder="Title" />
          </Form.Item>
          <Form.Item
            name="summary"
            rules={[{ required: true, message: "This is required to upload" }]}
          >
            <Input.TextArea placeholder="Summary Description" rows={6} />
          </Form.Item>
          <Upload {...props}>
            <Button disabled={isDisable}>Upload</Button>
          </Upload>
        </Form>
      </Modal>
    </div>
  );
};
