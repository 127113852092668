import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { windowSize } from "../recoil";
import { Tile } from "../Tile/Tile";
import homeIcon from "./../Home/Home.png";
import { Button } from "antd";
import { logEvent } from "firebase/analytics";
import { analytics } from "../linker";

export const Info = () => {
  const { height, width } = useRecoilValue(windowSize);
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);
  useEffect(() => {
    logEvent(analytics, "info", { Location: "information" });
  }, []);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "clip",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "min(800px, 90vw)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {height < width && (
          <div
            style={{
              color: "#9F9F9F",
              padding: ".5rem",
              borderBottom: "1px solid rgb(40,40,40)",
              borderBottomColor: "rgb(100,180,255)",
              fontSize: "2rem",
            }}
          >
            About Me
          </div>
        )}
        {height > width && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid rgb(40,40,40)",
              borderBottomColor: "rgb(100,180,255)",
            }}
          >
            <div
              style={{
                color: "#9F9F9F",
                padding: "1rem",
                fontSize: "1.5rem",
              }}
            >
              About Me
            </div>
          </div>
        )}

        <div
          style={{
            height: "80%",
            overflowY: "scroll",
            padding: "1rem",
            borderBottom: "1px solid rgb(40,40,40)",
            borderBottomColor: "rgb(100,180,255)",
          }}
        >
          <div
            style={{
              color: "#9F9F9F",
              fontSize:
                height > width
                  ? width < 800
                    ? ".8rem"
                    : "1rem"
                  : width < 1200
                  ? ".8rem"
                  : "1rem",
            }}
          >
            <div style={{ marginBottom: "1rem" }}>
              In my professional capacity, I have served in the military, the
              healthcare/science sector, in countering human trafficking, and in
              investigative journalism. Each of these roles has contributed to
              developing my skill base, work ethic, and problem solving ability.
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <ul>
                <li>I excel in analytical and strategic problem solving.</li>
                <li>
                  I take a first principles approach to problem solving,
                  preferring to reduce a problem down to a sequence of
                  interdependent cause-and-effect events that lead to an
                  outcome.
                </li>
                <li>
                  I tend to avoid absolutes, preferring to operate on a
                  probability scale.
                </li>
                <li>
                  I enjoy learning new concepts so that I am well informed when
                  facing new challenges.
                </li>
                <li>
                  I am a strategic planner but at the same time highly adaptable
                  in a changing situation.
                </li>
                <li>
                  My adaptability is a result of having both a structured and
                  creative nature.
                </li>
              </ul>
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: width < 800 ? "1.2rem" : "1.5rem",
                paddingBottom: ".5rem",
              }}
            >
              Experience
            </div>
            <div style={{ fontWeight: "bold" }}>Military</div>
            <ul>
              <li>Infantry & Reconnaissance</li>
              <li>Counter-Terrorist Operations</li>
              <li>
                Special Operations Forces (SOF) - New Zealand Special Air
                Service (NZSAS)
              </li>
            </ul>
            <div style={{ fontWeight: "bold" }}>
              Intelligence Systems and Analysis
            </div>
            <ul>
              <li>
                Development of an intelligence operations capability for
                countering human trafficking
              </li>
              <li>
                Intelligence indicators information management software
                development
              </li>
              <li>
                Identification and investigations of Child Sexual Exploitation
              </li>
              <li>
                Identification and investigations of transnational and regional
                human trafficking, including the identification of human
                trafficking victims
              </li>
            </ul>
            <div style={{ fontWeight: "bold" }}>Health Science</div>
            <ul>
              <li>Bachelor of Medical Laboratory Science (BMLSc)</li>
              <li>Practiced in Chemical Pathology</li>
            </ul>
            <div style={{ fontWeight: "bold" }}>Communication</div>
            <ul>
              <li>Public Speaking - Toastmasters</li>
              <li>
                Science Communication - Videography, creative non-fiction
                writing, and oral presentation
              </li>
            </ul>
            <div style={{ fontWeight: "bold" }}>
              Information Technology & Software Development
            </div>
            <ul>
              <li>
                Languages: Javascript, Typescript, HTML, CSS; some Python and
                C++
              </li>
              <li>Frameworks: React</li>
              <li>
                Environments: Google Cloud Platform - Firebase, Compute Engine
                and Google APIs
              </li>
              <li>Experience with PostgreSQL, GraphQL and MongoDB</li>
              <li>
                Experience with fullstack development - Apollo Server, Node
                Media Servers and React
              </li>
              <li>
                Experience with Artificial Intelligence server integration -
                ChatGPT API, DALL-E.
              </li>
              <li>
                Experience with micro-controller integration via HTTP
                communication for data recorded and control- Raspberry Pi,
                Arduino, Apollo server, React
              </li>
            </ul>
            <div style={{ fontWeight: "bold" }}>Leadership</div>
            <ul style={{ paddingBottom: "2rem" }}>
              <li>Business Development</li>
              <li>Operational Strategy</li>
              <li>Policy Development</li>
              <li>Recipient of a university level leadership award</li>
            </ul>
            <div
              style={{
                fontWeight: "bold",
                fontSize: width < 800 ? "1.2rem" : "1.5rem",
                paddingBottom: ".5rem",
              }}
            >
              My Career - In detail
            </div>
            <div style={{ marginBottom: "1rem" }}>
              Born and raised in the Waimakariri district, I attended Rangiora
              High School.
            </div>
            <div style={{ marginBottom: "1rem" }}>
              I served an eight year military career in the New Zealand Army. I
              was initially posted to D Coy, 2nd/1st Infantry Battalion - with
              whom I deployed to East Timor. My second posting was into a
              Reconnaissance Platoon where I specialised as a Visual Tracker
              receiving training in both New Zealand and in Malaysia. My role as
              a tracker was to collect intelligence on enemy forces and relay
              that information to senior planners. My next posting was in the
              Commando Squadron, which at the time was the New Zealand Defense
              Force's domestic counter-terrorist capability. My final posting in
              the New Zealand Defense Force was as a Trooper in the New Zealand
              Special Air Services (NZSAS). In this role I specialised in Visual
              Tracking, Mountain Warfare, and Patrol Medicine, along with the
              other functions of a Special Operations Forces soldier. I also
              instructed on several courses during this time.
            </div>
            <div style={{ marginBottom: "1rem" }}>
              Following my military career, I completed a degree in Medical
              Laboratory Science (BMLSc) at the University of Otago. Once I had
              attained my degree I practiced as a scientist in a hospital
              laboratory where I specialised in Chemical Pathology
              (Biochemistry).
            </div>
            <div style={{ marginBottom: "1rem" }}>
              After working as a scientist, I joined an anti human trafficking
              organisation in Thailand as an Intelligence Analyst. My role was
              to build an intelligence capability tailored to anti-human
              trafficking. The objectives were to identify human trafficking
              through open-source intelligence, forensic intelligence, and
              human-intelligence. While in this role I began to write software
              for intelligence analysis and information collection.
            </div>
            <div style={{ marginBottom: "1rem" }}>
              During the COVID-19 period I worked with Operation People where I
              focused on building capability and investigating censorship.
              During this time I was able to meet a lot of great people from all
              walks of life in New Zealand.
            </div>
            <div style={{ marginBottom: "2rem" }}>
              I am now continuing to develop my software development and
              investigative skills. I am currently applying my skill set to
              within three areas. The first is in researching political trends
              within New Zealand, especially concerning the maintenance of
              democratic values. The second focus is in countering human
              trafficking, and the third is, how to open up the public square
              for a broader competition of ideas.
            </div>

            <div style={{ marginBottom: "2rem" }}>
              I am also honoured to be a signatory on the Westminster
              Declaration. An initiative supporting the freedom of expression:
              <Button
                type="link"
                style={{ color: "rgb(255,150,150)" }}
                onClick={() =>
                  window.open("https://westminsterdeclaration.org/")
                }
              >
                Westminster Declaration
              </Button>
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: width < 800 ? "1.2rem" : "1.5rem",
                paddingBottom: ".5rem",
              }}
            >
              Interests
            </div>
            <div style={{ marginBottom: "1rem" }}>
              I am interested in early New Zealand history and in particular the
              interaction of the Europeans and Maori. I enjoy reading material
              written prior the the 1950s on the subject. I think there are a
              lot of great stories from that time where both Europeans and Maori
              worked together in interesting ways.
            </div>
            <div style={{ marginBottom: "1rem" }}>
              I am also interested in how totalitarian systems develop and how
              democratic systems respond to totalitarian pressures. I like to
              read about the pasts worst totalitarian systems, such as the
              Communist Soviet Union, NAZI Germany, Communist China, and the
              others. I believe having an understanding of the mechanisms that
              drive these systems is key to maintaining a free and democratic
              society and preventing our own descent into such systems.
            </div>
            <div style={{ marginBottom: "1rem" }}>
              Totalitarian systems require total societal capitulation to an
              ideology. As a result, the mechanisms driving totalitarianism are
              very much intwined with all aspects of society. This makes the
              study of totalitarianism a "big beast" to tackle where there is
              always more to learn!
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: width < 800 ? "1.2rem" : "1.5rem",
                paddingBottom: ".5rem",
              }}
            >
              My Recreational Life
            </div>
            <div style={{ marginBottom: "1rem" }}>
              As a natural learner, I love learning new things. Over my
              recreational life I have enjoyed more technical sports such as
              rock climbing, mountaineering, mountain biking, tramping, hunting,
              kick boxing, and film making.
            </div>
          </div>
        </div>
        <div style={{ padding: ".5rem" }}>
          <Tile imageSrc={homeIcon} address="/" width="2vh" height="2vh" />
        </div>
      </div>
    </div>
  );
};
