import {
  Button,
  Form,
  Input,
  Popover,
  Radio,
  StepProps,
  Steps,
  Switch,
  message,
} from "antd";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
} from "recharts";
import { db } from "../linker";
import { useRecoilValue } from "recoil";
import { windowSize } from "../recoil";
import { InfoCircleFilled } from "@ant-design/icons";

type GraphData = {
  [key: string]: string;
};

export const Graph = () => {
  const [graphData, setGraphData] = useState<Array<GraphData>>([]);
  const [current, setCurrent] = useState(0);
  const [differenceOnly, setDifferenceOnly] = useState(false);
  const [playAni, setPlayAni] = useState(false);
  const [selector, setSelector] = useState({
    itemOne: false,
    itemTwo: false,
    itemThree: false,
    itemFour: false,
    itemFive: false,
    itemSix: false,
  });
  const [dataForm] = Form.useForm();
  const data = [{ name: "Page A", uv: 400, pv: 2400, amt: 2400 }];
  const { width, height } = useRecoilValue(windowSize);

  useEffect(() => {
    const getData = getDoc(doc(db, `graph/1710591401369`))
      .then((snapDoc) => {
        const tempData = snapDoc.data();

        if (!tempData) return;

        const dif = tempData.data.map((item: any) => {
          return {
            ...item,
            difference050: item["0"] - item["50"],
            difference50100: item["50"] - item["100"],
            difference100200: item["100"] - item["200"],
            difference200400: item["200"] - item["400"],
            difference400800: item["400"] - item["800"],
          };
        });

        console.log({ tempData });
        setGraphData(dif as Array<GraphData>); //
      })
      .then(() => {
        setSelector((prev) => ({ ...prev, itemOne: true }));
      })
      .catch((err) => {
        message.error("Failed to load data");
      });
  }, []);

  return (
    <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* <Button
          onClick={() =>
            setDoc(doc(db, `graph/${new Date().getTime().toString()}`), {
              complete: false,
              date: new Date().getTime(),
            })
          }
        >
          Trigger
        </Button> */}
        {/* <Button
          onClick={() =>
            dataForm
              .validateFields()
              .then((values) => {
                getDoc(doc(db, `graph/1710591401369`)).then((snapDoc) => {
                  const tempData = snapDoc.data();

                  if (!tempData) return;

                  const dif = tempData.data.map((item: any) => {
                    return {
                      ...item,
                      difference050: item["0"] - item["50"],
                      difference50100: item["50"] - item["100"],
                      difference100200: item["100"] - item["200"],
                      difference200400: item["200"] - item["400"],
                      difference400800: item["400"] - item["800"],
                    };
                  });

                  console.log({ tempData });
                  setGraphData(dif as Array<GraphData>); //
                });
              })
              .then(() => {
                setSelector((prev) => ({ ...prev, itemOne: true }));
              })
              .catch((err) => {
                message.error("Failed to load data");
              })
          }
        >
          Load Data
        </Button> */}
        {/* <Form form={dataForm}>
          <Form.Item name="dataId">
            <Input placeholder="Data Id" />
          </Form.Item>
        </Form> */}
      </div>
      <div style={{ padding: "3rem" }}>
        <div
          style={{
            color: "whitesmoke",
            width: "100%",
            textAlign: "center",
            fontSize: "1.5rem",
            marginBottom: ".5rem",
          }}
        >
          Atmospheric energy loss; and retention due to atmospheric carbon
          dioxide
        </div>
        <div
          style={{
            color: "rgb(200,200,200)",
            width: "100%",
            textAlign: "center",
          }}
        >
          Data supplied by Prof. William Happer, Princeton University.
        </div>
        <AreaChart
          width={width * 0.9}
          height={height * 0.7}
          data={graphData}
          title="Carbon Dioxide Energy Absorption"
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          {selector.itemOne && (
            <>
              {/* <Line type="monotone" dataKey="0" stroke="#0CC42D" dot={false} /> */}
              <Area
                type="monotone"
                dataKey="0"
                stroke={differenceOnly ? "none" : "#0CC42D"}
                fill={differenceOnly ? "none" : "#0CC42D"}
              />
            </>
          )}

          {selector.itemTwo && (
            <>
              {/* <Line type="monotone" dataKey="50" stroke="#7FDF0A" dot={false} /> */}
              <Area
                type="monotone"
                dataKey="50"
                stroke={differenceOnly ? "none" : "#7FDF0A"}
                fill={differenceOnly ? "none" : "#7FDF0A"}
                stackId={"1"}
              />
              {selector.itemOne && selector.itemTwo && differenceOnly && (
                <Area
                  type="monotone"
                  dataKey="difference050"
                  stroke="none"
                  fill="#7FDF0A"
                  fillOpacity={1}
                  stackId={"1"}
                />
              )}
            </>
          )}

          {selector.itemThree && (
            <>
              {/* <Line
                type="monotone"
                dataKey="100"
                stroke="#D7DF0A"
                dot={false}
              /> */}
              <Area
                type="monotone"
                dataKey="100"
                stroke={differenceOnly ? "none" : "#D7DF0A"}
                fill={differenceOnly ? "none" : "#D7DF0A"}
                stackId={"2"}
              />
              {selector.itemTwo && selector.itemThree && differenceOnly && (
                <Area
                  type="monotone"
                  dataKey="difference50100"
                  stroke="none"
                  fill="#D7DF0A"
                  fillOpacity={1}
                  stackId={"2"}
                />
              )}
            </>
          )}
          {selector.itemFour && (
            <>
              <Area
                type="monotone"
                dataKey="200"
                stroke={differenceOnly ? "none" : "#DF940A"}
                fill={differenceOnly ? "none" : "#DF940A"}
                stackId={"3"}
              />
              {selector.itemFour && selector.itemThree && differenceOnly && (
                <Area
                  type="monotone"
                  dataKey="difference100200"
                  stroke="none"
                  fill="#DF940A"
                  fillOpacity={1}
                  stackId={"3"}
                />
              )}
            </>
            // <Line type="monotone" dataKey="200" stroke="#DF940A" dot={false} />
          )}
          {selector.itemFive && (
            <>
              <Area
                type="monotone"
                dataKey="400"
                stroke={differenceOnly ? "none" : "#DF3B0A"}
                fill={differenceOnly ? "none" : "#DF3B0A"}
                stackId={"4"}
              />
              {selector.itemFour && selector.itemFive && differenceOnly && (
                <Area
                  type="monotone"
                  dataKey="difference200400"
                  stroke="none"
                  fill="#DF3B0A"
                  fillOpacity={1}
                  stackId={"4"}
                />
              )}
            </>

            // <Line type="monotone" dataKey="400" stroke="#DF3B0A" dot={false} />
          )}
          {selector.itemSix && (
            <>
              <Area
                type="monotone"
                dataKey="800"
                stroke={differenceOnly ? "none" : "#FF0909"}
                fill={differenceOnly ? "none" : "#FF0909"}
                stackId={"5"}
              />{" "}
              {selector.itemSix && selector.itemFive && differenceOnly && (
                <Area
                  type="monotone"
                  dataKey="difference400800"
                  stroke="none"
                  fill="#FF0909"
                  fillOpacity={1}
                  stackId={"5"}
                />
              )}
            </>
            // <Line type="monotone" dataKey="800" stroke="#FF0909" dot={false} />
          )}
          {/* <Line type="monotone" dataKey="50" stroke="#8884d8" /> */}
          <YAxis
            domain={[0, 450]}
            label={{ value: "Energy Lost", angle: -90, position: "insideLeft" }}
          />
          <XAxis
            dataKey="Freq"
            fontSize={10}
            label={{
              value: "Wavelength Freq (cm-1)",
              position: "insideBottom",
            }}
          />
        </AreaChart>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            color: "#0CC42D",
            display: "flex",
            flexDirection: "row",
            padding: ".25rem",
          }}
        >
          <Popover
            content={
              <div style={{ maxWidth: "300px" }}>
                This data shows the amount of energy lost from the atmosphere in
                the absence of atmospheric carbon dioxide - the baseline energy
                loss.
              </div>
            }
          >
            <div style={{ paddingRight: ".5rem" }}>0</div>
          </Popover>
          <Switch
            checked={selector.itemOne}
            onChange={() =>
              setSelector((prev) => ({
                ...prev,
                itemOne: selector.itemOne ? false : true,
              }))
            }
          />
        </div>
        <div
          style={{
            color: "#7FDF0A",
            display: "flex",
            flexDirection: "row",
            padding: ".25rem",
          }}
        >
          <Popover
            content={
              <div style={{ maxWidth: "300px" }}>
                Click to see the effect of a carbon dioxide concentration of 50
                parts per million on atmospheric energy retention.
              </div>
            }
          >
            <div style={{ paddingRight: ".5rem" }}>50</div>
          </Popover>
          <Switch
            checked={selector.itemTwo}
            onChange={() =>
              setSelector((prev) => ({
                ...prev,
                itemTwo: selector.itemTwo ? false : true,
              }))
            }
          />{" "}
        </div>
        <div
          style={{
            color: "#D7DF0A",
            display: "flex",
            flexDirection: "row",
            padding: ".25rem",
          }}
        >
          <Popover
            content={
              <div style={{ maxWidth: "300px" }}>
                Click to see the effect of a carbon dioxide concentration of 100
                parts per million on atmospheric energy retention.
              </div>
            }
          >
            <div style={{ paddingRight: ".5rem" }}>100</div>
          </Popover>
          <Switch
            checked={selector.itemThree}
            onChange={() =>
              setSelector((prev) => ({
                ...prev,
                itemThree: selector.itemThree ? false : true,
              }))
            }
          />{" "}
        </div>
        <div
          style={{
            color: "#DF940A",
            display: "flex",
            flexDirection: "row",
            padding: ".25rem",
          }}
        >
          <Popover
            content={
              <div style={{ maxWidth: "300px" }}>
                Click to see the effect of a carbon dioxide concentration of 200
                parts per million on atmospheric energy retention.
              </div>
            }
          >
            <div style={{ paddingRight: ".5rem" }}>200</div>
          </Popover>
          <Switch
            checked={selector.itemFour}
            onChange={() =>
              setSelector((prev) => ({
                ...prev,
                itemFour: selector.itemFour ? false : true,
              }))
            }
          />{" "}
        </div>
        <div
          style={{
            color: "#DF3B0A",
            display: "flex",
            flexDirection: "row",
            padding: ".25rem",
          }}
        >
          <Popover
            content={
              <div style={{ maxWidth: "300px" }}>
                Click to see the effect of a carbon dioxide concentration of 400
                parts per million on atmospheric energy retention.
              </div>
            }
          >
            <div style={{ paddingRight: ".5rem" }}>400</div>
          </Popover>
          <Switch
            checked={selector.itemFive}
            onChange={() =>
              setSelector((prev) => ({
                ...prev,
                itemFive: selector.itemFive ? false : true,
              }))
            }
          />
        </div>
        <div
          style={{
            color: "#FF0909",
            display: "flex",
            flexDirection: "row",
            padding: ".25rem",
          }}
        >
          <Popover
            content={
              <div style={{ maxWidth: "300px" }}>
                Click to see the effect of a carbon dioxide concentration of 800
                parts per million on atmospheric energy retention.
              </div>
            }
          >
            <div style={{ paddingRight: ".5rem" }}>800</div>
          </Popover>
          <Switch
            checked={selector.itemSix}
            title="Six"
            onChange={() =>
              setSelector((prev) => ({
                ...prev,
                itemSix: selector.itemSix ? false : true,
              }))
            }
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() =>
              differenceOnly
                ? setDifferenceOnly(false)
                : setDifferenceOnly(true)
            }
          >
            {differenceOnly ? "Difference" : "Energy Lost"}
          </Button>
          <Popover
            content={
              <div style={{ maxWidth: "300px" }}>
                Select two or more consecutive carbon dioxide concentrations to
                see how much energy is retained between two values.
              </div>
            }
          >
            <InfoCircleFilled
              rev="nill"
              style={{ color: "whitesmoke", marginLeft: ".5rem" }}
            />
          </Popover>
        </div>
        <Button
          onClick={() => {
            if (playAni) {
              setPlayAni(false);
            } else {
              setSelector({
                itemOne: true,
                itemTwo: false,
                itemThree: false,
                itemFour: false,
                itemFive: false,
                itemSix: false,
              });
              setPlayAni(true);
            }
          }}
        >
          {playAni ? "Stop Animation" : "Play Animation"}
        </Button>
      </div>
      {selector.itemOne && playAni && (
        <audio
          autoPlay
          src="https://firebasestorage.googleapis.com/v0/b/philshawnz-2e6b1.appspot.com/o/audio%2FInitial.mp3?alt=media&token=64a6551f-45a0-4d10-8d1a-a1d8bd03c99d"
          onEnded={() => {
            setSelector((prev) => ({ ...prev, itemTwo: true }));
            setCurrent(1);
          }}
        />
      )}
      {selector.itemTwo && playAni && (
        <audio
          autoPlay
          src="https://firebasestorage.googleapis.com/v0/b/philshawnz-2e6b1.appspot.com/o/audio%2F2.mp3?alt=media&token=edc46715-249a-451a-b5e2-6039328e8d23"
          onEnded={() => {
            setSelector((prev) => ({ ...prev, itemThree: true }));
            setCurrent(2);
          }}
        />
      )}
      {selector.itemThree && playAni && (
        <audio
          autoPlay
          src="https://firebasestorage.googleapis.com/v0/b/philshawnz-2e6b1.appspot.com/o/audio%2F3.mp3?alt=media&token=d5e2793c-d395-4344-a745-4929514ad2dd"
          onEnded={() => {
            setSelector((prev) => ({ ...prev, itemFour: true }));
            setCurrent(3);
          }}
        />
      )}
      {selector.itemFour && playAni && (
        <audio
          autoPlay
          src="https://firebasestorage.googleapis.com/v0/b/philshawnz-2e6b1.appspot.com/o/audio%2F4.mp3?alt=media&token=6abc4b98-b55b-4d44-9ae4-80db853bc9b3"
          onEnded={() => {
            setSelector((prev) => ({ ...prev, itemFive: true }));
            setCurrent(4);
          }}
        />
      )}
      {selector.itemFive && playAni && (
        <audio
          autoPlay
          src="https://firebasestorage.googleapis.com/v0/b/philshawnz-2e6b1.appspot.com/o/audio%2F400.mp3?alt=media&token=93042000-4896-4841-be6c-dca069792f5c"
          onEnded={() => {
            setSelector((prev) => ({ ...prev, itemSix: true }));
            setCurrent(5);
          }}
        />
      )}
      {selector.itemSix && playAni && (
        <audio
          autoPlay
          src="https://firebasestorage.googleapis.com/v0/b/philshawnz-2e6b1.appspot.com/o/audio%2F5.mp3?alt=media&token=d77c46b8-0dab-47ea-b027-287e61d22039"
          onEnded={() => setPlayAni(false)}
        />
      )}
    </div>
  );
};
