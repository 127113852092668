import { Drawer, Collapse, Button } from "antd";
import { useState } from "react";
import type { TeamDataType, GameDataType, TeamInfoType } from "../../types";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { Clue } from "./Clue";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../linker";

type ClueDrawerProps = {
  teamData: TeamDataType[];
  gameData: GameDataType[];
  teamInfo: TeamInfoType | null;
};

export const ClueDrawer = (props: ClueDrawerProps) => {
  const [showClues, setShowClues] = useState(false);
  const [isFirstHint, setIsFirstHint] = useState(false);
  const [isSecondHint, setIsSecondHint] = useState(false);
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const team = props.teamData.find(
    (team) => team.teamName === props.teamInfo?.name
  );

  if (!team) return null;

  const completeObjectives = team.teamObjectivesComplete;

  const incompleteObjectives = props.gameData.filter((obj) => {
    const filteredArray = completeObjectives.filter(
      (obj2) => obj2.id === obj.id
    );

    return filteredArray.length > 0 ? false : true;
  });

  return (
    <>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: "20%",
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "2.5rem",
          height: "3rem",
          backgroundColor: "black",
          color: "white",
          borderRadius: "6px 0px 0px 6px",
          cursor: "pointer",
        }}
        onClick={() => setShowClues((show) => !show)}
      >
        {!showClues ? (
          <DoubleLeftOutlined rev="true" />
        ) : (
          <DoubleRightOutlined rev="true" />
        )}
      </div>

      <Drawer
        title="Clues"
        placement="right"
        size="default"
        onClose={() => setShowClues(false)}
        open={showClues}
      >
        <Collapse
          bordered={false}
          style={{ background: "transparent" }}
          items={incompleteObjectives
            .filter((item) => !item.isDeleted)
            .map((item) => {
              return {
                key: item.id,
                label: item.description,
                style: {
                  marginBottom: 24,
                  border: "1px solid #555",
                  background: "#333",
                  borderRadius: "6px",
                },
                children: (
                  <div>
                    <Button
                      size="small"
                      style={{ marginBottom: ".25rem" }}
                      onClick={() => {
                        updateDoc(doc(db, `teams/${team.teamName}`), {
                          deductions: team.deductions + 1,
                        });
                        setActiveItem(item.id);
                        setIsFirstHint(true);
                      }}
                    >
                      Hint 1
                    </Button>
                    {isFirstHint && activeItem === item.id && (
                      <div
                        style={{ marginBottom: ".25rem" }}
                      >{`${item.hintOne}`}</div>
                    )}
                    {isFirstHint && activeItem === item.id && (
                      <Button
                        size="small"
                        style={{ marginBottom: ".25rem" }}
                        onClick={() => {
                          updateDoc(doc(db, `teams/${team.teamName}`), {
                            deductions: team.deductions + 1,
                          });

                          setIsSecondHint(true);
                        }}
                      >
                        Hint 2
                      </Button>
                    )}
                    {isSecondHint && activeItem === item.id && (
                      <div
                        style={{ marginBottom: "1rem" }}
                      >{`${item.hintTwo}`}</div>
                    )}
                    <Clue
                      teamName={team.teamName}
                      item={item}
                      completedObjectives={completeObjectives}
                    />{" "}
                  </div>
                ),
              };
            })}
          defaultActiveKey={
            incompleteObjectives.filter((item) => !item.isDeleted).at(0)?.id
          }
          onChange={() => {
            setIsFirstHint(false);
            setIsSecondHint(false);
            setActiveItem(null);
          }}
        />
      </Drawer>
    </>
  );
};
