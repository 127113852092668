import { collection, onSnapshot } from "firebase/firestore";
import { atom } from "recoil";
import { db } from "./linker";
import { Project } from "./types";

type Question = {
  name: string | null;
  question: string | null;
};

export const windowSize = atom<{ width: number; height: number }>({
  key: "windowState",
  default: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  effects: [
    ({ setSelf }) => {
      const listener = () => {
        setSelf({
          width: window.screenX,
          height: window.screenY,
        });
      };
      window.addEventListener("resize", listener);
      return () => {
        window.removeEventListener("resize", listener);
      };
    },
  ],
});

export const questionState = atom<Question>({
  key: "questionState",
  default: { name: null, question: null },
});

export const projectState = atom<Array<Project> | null>({
  key: "projectStateKey",
  default: null,
  effects: [
    ({ setSelf }) => {
      let projectData: Array<Project> = [];
      onSnapshot(collection(db, "projects"), (snap) => {
        projectData = [];
        snap.docs.forEach((doc) => {
          projectData.push(doc.data() as Project);
        });
        setSelf(projectData);
      });
    },
  ],
});
