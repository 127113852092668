import { Button, Image } from "antd";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Tile } from "../Tile/Tile";
import { db } from "../linker";
import homeIcon from "./../Home/Home.png";

type SlideShow = { currentSlide: number; slideSet: Array<string> };

export const SlideControl = () => {
  const [slideState, setSlideState] = useState<{
    id: string;
    show: SlideShow;
  } | null>(null);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  useEffect(() => {
    setSlideState(null);
    onSnapshot(collection(db, "slides"), (snapshot) =>
      snapshot.docs.forEach((doc) =>
        setSlideState(() => {
          return { id: doc.id, show: doc.data() as SlideShow };
        })
      )
    );
    console.log(slideState);
  }, []);

  const changeSlide = (direction: string) => {
    slideState &&
      updateDoc(doc(db, `slides/${slideState?.id}`), {
        currentSlide:
          direction === "next"
            ? slideState.show.currentSlide + 1
            : slideState.show.currentSlide - 1,
      });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Image
        src={slideState?.show.slideSet[slideState.show.currentSlide]}
        height="40vh"
      />
      <div style={{ color: "white" }}>
        {slideState && slideState.show.currentSlide + 1}
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Button onClick={() => changeSlide("prev")}>Prev</Button>
        <Button onClick={() => changeSlide("next")}>Next</Button>
      </div>
      <Image
        src={slideState?.show.slideSet[slideState.show.currentSlide + 1]}
        height="40vh"
      />
    </div>
  );
};
