import { logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { analytics } from "../linker";
import { windowSize } from "../recoil";
import { Tile } from "../Tile/Tile";
import messageIcon from "./Message.png";
import personIcon from "./Person.png";
import imageFront from "./../Home/Phil.png";
import questionIcon from "./../Home/question.png";
import { Button, Form, Input, Modal, message } from "antd";
import { useAuth } from "../AuthProvider/AuthProvider";
import { useNavigate } from "react-router-dom";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";

export const Dashboard = () => {
  const tileContent = [
    { icon: personIcon, address: "information", note: "Information about me" },
    { icon: messageIcon, address: "contact", note: "Send me some information" },
  ];
  const buttons = [
    { label: "Results", path: "/results" },
    { label: "Experiment", path: "/experiment" },
    { label: "Messages", path: "/messages" },
    { label: "Slide Controller", path: "/slide-controller" },
    { label: "Slides", path: "/slides" },
    { label: "Games", path: "/game-editor" },
    { label: "Notes", path: "/notes" },
    { label: "Time", path: "/time" },
    { label: "Generate", path: "/generate" },
    { label: "PDF", path: "/pdf" },
    { label: "Graph", path: "/carbon-dioxide-energy-loss-retention-data-tool" },
  ];
  const { width, height } = useRecoilValue(windowSize);
  const goTo = useNavigate();
  useEffect(() => {
    logEvent(analytics, "page", { Location: "dashboard" });
  }, []);

  const auth = useAuth();
  const [loginForm] = Form.useForm();
  const [isModal, setIsModal] = useState(false);
  const handleLogin = () => {
    loginForm
      .validateFields()
      .then((values) => {
        auth.login(values.email, values.password);
        setIsModal(false);
      })
      .catch((err) => {
        message.error("Error");
        console.error(err);
      });
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: height > width ? "column" : "row",
        alignItems: "center",
        overflow: "clip",
      }}
    >
      {height < width && (
        <img
          src={imageFront}
          style={{
            height: "min(90vh, 50vw)",
            width: "min(calc(90vh * .73), calc(50vw * .73))",
            position: "relative",
            bottom: "0px",
            alignSelf: "end",
          }}
          alt="Foreground"
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            color: "rgb(150,150,150)",
            padding: "3rem",

            fontSize: width < 600 ? "1rem" : "2rem",
          }}
          onClick={() => setIsModal(true)}
        >
          philshaw.nz
        </div>
        {auth.currentUser ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            {buttons.map((item, idx) => {
              return (
                <Button
                  ghost
                  type="primary"
                  key={idx}
                  style={{ marginBottom: ".25rem" }}
                  onClick={() => goTo(item.path)}
                >
                  {item.label}
                </Button>
              );
            })}
            <Button
              type="primary"
              ghost
              onClick={() => auth.logout()}
              icon={<LogoutOutlined rev />}
              style={{ alignSelf: "center" }}
            />
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {tileContent.map((item, idx) => {
              return (
                <Tile
                  key={idx}
                  imageSrc={item.icon}
                  address={item.address}
                  note={item.note}
                />
              );
            })}
          </div>
        )}
      </div>
      {height > width && (
        <img
          src={imageFront}
          style={{
            height: "50vh",
            width: "calc(50vh * .73)",
            position: "relative",

            bottom: "-25vh",
          }}
          alt="Foreground"
        />
      )}
      <Modal open={isModal} onCancel={() => setIsModal(false)} footer={null}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "2rem",
          }}
        >
          <>
            <Form form={loginForm}>
              <Form.Item name="email">
                <Input type="email" name="email" placeholder="Email" />
              </Form.Item>
              <Form.Item name="password">
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Form>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                paddingBottom: "2rem",
              }}
            >
              <Button
                type="primary"
                onClick={() => handleLogin()}
                icon={<LoginOutlined rev />}
              />
            </div>
          </>
        </div>
      </Modal>
    </div>
  );
};
