import { Button } from "antd";
import { GameDataType } from "../../types";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

(window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const PDFPrint = ({ data }: { data: Array<GameDataType> }) => {
  const content = data
    .filter((item) => !item.isDeleted)
    .map((item, idx) => {
      return {
        text: `${(idx + 1).toString()}.  ${
          item.description
        } \n \n Answer:  \n \n \n`,
      };
    });

  const hint = data
    .filter((item) => !item.isDeleted)
    .map((item, idx) => {
      return {
        text: `${(idx + 1).toString()}. ${item.description} \n \n ${
          item.hintOne
        } \n \n ${item.hintTwo} \n \n \n`,
      };
    });

  return (
    <div>
      <Button onClick={() => pdfMake.createPdf({ content: content }).open()}>
        Print Clues
      </Button>
      <Button onClick={() => pdfMake.createPdf({ content: hint }).open()}>
        Print Hints
      </Button>
    </div>
  );
};
