import { Drawer } from "antd";
import { useState, useMemo } from "react";
import type { TeamDataType } from "../../types";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

type TeamDrawerProps = {
  teamData: TeamDataType[];
};

export const TeamDrawer = (props: TeamDrawerProps) => {
  const [showTeams, setShowTeams] = useState(false);

  const highScore = useMemo(
    () =>
      props.teamData.reduce(
        (acc, team) =>
          acc > (team.teamScore || 0) ? acc : team.teamScore || 0,
        0
      ),
    [props.teamData]
  );

  return (
    <>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: "20%",
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "2.5rem",
          height: "3rem",
          backgroundColor: "black",
          color: "white",
          borderRadius: "0px 6px 6px 0px",
          cursor: "pointer",
        }}
        onClick={() => setShowTeams((show) => !show)}
      >
        {showTeams ? (
          <DoubleLeftOutlined rev="true" />
        ) : (
          <DoubleRightOutlined rev="true" />
        )}
      </div>

      <Drawer
        title="Teams"
        placement="left"
        size="default"
        onClose={() => setShowTeams(false)}
        open={showTeams}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          {props.teamData
            .filter((item) => item.teamName !== "GodMode")
            .sort((a, b) => (b.teamScore || 0) - (a.teamScore || 0))
            .map((team, idx) => (
              <div
                key={team.teamName}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: `${((team.teamScore || 0) / highScore) * 100}%`,
                    height: "3rem",
                    backgroundColor: team.color,
                    borderRadius: "6px",
                    display: "flex",
                    fontSize: "1.1rem",
                    alignItems: "center",
                    fontWeight: "bold",
                    justifyContent: "space-between",
                    boxSizing: "border-box",
                    padding: "0 1rem",
                    transition: "width 1s",
                  }}
                >
                  <div>{team.teamName}</div>
                  <div>{(team.teamScore || 0) - team.deductions}</div>
                </div>
              </div>
            ))}
        </div>
      </Drawer>
    </>
  );
};
