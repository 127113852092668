import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../linker";
import { Button, Popconfirm } from "antd";

type QuestionType = {
  name: string;
  question: string;
  mod: boolean;
  answered: boolean;
  date: number;
  id: string;
};

export const Results = () => {
  const [questionList, setQuestionList] = useState<Array<QuestionType>>([]);

  useEffect(() => {
    const listRef = collection(db, "questions");
    return onSnapshot(listRef, (snapshot) => {
      setQuestionList([]);
      snapshot.docs.forEach((doc) => {
        setQuestionList((prev) => [
          ...prev,
          { ...doc.data(), id: doc.id } as QuestionType,
        ]);
      });
    });
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2.5vh",
      }}
    >
      <div
        style={{ color: "white", alignSelf: "center" }}
      >{`Audience Questions | ${questionList.length}`}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <div style={{ color: "rgb(100, 255, 100)" }}>Moderated</div>
        <div style={{ color: "rgb(255, 100, 100)" }}>Not moderated</div>
        <div style={{ color: "rgb(100, 100, 100)" }}>Answered</div>
      </div>
      <div
        style={{
          maxWidth: "600px",
          marginTop: "1rem",
          height: "75vh",
          overflowY: "scroll",
        }}
      >
        {questionList
          .sort((itemA, itemB) => itemB.date - itemA.date)
          .map((item, idx) => {
            return (
              <div
                key={idx}
                style={{
                  color: item.answered ? "rgba(255, 255, 255, .4)" : "white",
                  alignSelf: "center",
                  border: "1px solid rgb(150,150,150)",
                  borderColor: item.answered
                    ? "rgba(255, 255, 255, .1)"
                    : item.mod
                    ? "rgb(150, 255, 150)"
                    : "rgb(255,150,150)",
                  borderRadius: ".5rem",
                  padding: ".25rem",
                  marginBottom: ".25rem",
                  backgroundColor: item.answered
                    ? "rgba(150, 150, 150, .1)"
                    : item.mod
                    ? "rgba(0,255,0,.1)"
                    : "rgba(255,0,0,.1)",
                }}
              >
                <div>{item.name}</div>
                <div style={{ marginBottom: ".25rem", fontSize: "1rem" }}>
                  {item.question}
                </div>

                {!item.answered && (
                  <>
                    <Button
                      size="small"
                      onClick={() => {
                        item.mod
                          ? updateDoc(doc(db, `questions/${item.id}`), {
                              mod: false,
                            })
                          : updateDoc(doc(db, `questions/${item.id}`), {
                              mod: true,
                            });
                      }}
                    >
                      Mod
                    </Button>
                    <Popconfirm
                      title="Answered?"
                      onConfirm={() => {
                        updateDoc(doc(db, `questions/${item.id}`), {
                          answered: true,
                        });
                      }}
                    >
                      <Button size="small">Answered</Button>
                    </Popconfirm>
                  </>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
