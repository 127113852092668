import type { TeamDataType } from "../../types";
import { useMemo } from "react";

type TeamContentionProps = {
  teamData: TeamDataType[];
};

export const TeamContention = (props: TeamContentionProps) => {
  const totalScore = useMemo(
    () =>
      props.teamData.reduce((acc, team) => {
        return acc + (team.teamScore || 0) - team.deductions;
      }, 0),
    [props.teamData]
  );

  return (
    <div
      style={{
        display: "flex",
        height: "2rem",
        width: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "calc(50% - 1px)",
          height: "50%",
          border: "1px solid white",
        }}
      />
      {props.teamData.length > 0 &&
        props.teamData
          .filter((item) => item.teamName !== "GodMode")
          .map((team, i) => {
            return (
              <div
                key={team.teamName}
                style={{
                  display: "flex",
                  borderRadius:
                    i === 0
                      ? "5px 0 0 5px"
                      : i === props.teamData.length - 2 // sub +1 for GodMode
                      ? "0 5px 5px 0"
                      : "0",
                  backgroundColor: team.color,
                  alignItems: "center",
                  justifyContent:
                    props.teamData.length > 3 // add +1 for GodMode
                      ? "center"
                      : i === 0
                      ? "right"
                      : "left",
                  width: `${((team.teamScore || 0) / totalScore) * 100}%`,
                  boxSizing: "border-box",
                  padding: "0 1rem",
                  fontWeight: "bold",
                  transition: "width 0.5s",
                }}
              >
                {team.teamName}
              </div>
            );
          })}
    </div>
  );
};
