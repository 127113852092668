// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// const {
//   initializeAppCheck,
//   ReCaptchaEnterpriseProvider,
// } = require("firebase/app-check");

const firebaseConfig = {
  apiKey: "AIzaSyAQ5evpwknNj5jiFxEIiVH9kNnGUo8LqV0",
  authDomain: "philshawnz-2e6b1.firebaseapp.com",
  projectId: "philshawnz-2e6b1",
  storageBucket: "philshawnz-2e6b1.appspot.com",
  messagingSenderId: "507734262325",
  appId: "1:507734262325:web:8001dc9681eacccfc6277c",
  measurementId: "G-7CBX719YCL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const store = getStorage(app);
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaEnterpriseProvider(
//     "6LdexZAhAAAAAHWZ8n3NlRAxtHi8klmV_6uctCr6"
//   ),
//   isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
// });
export const db = getFirestore(app);
