import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Contact } from "../Contact/Contact";
import { Dashboard } from "../Dashboard/Dashboard";
import { Info } from "../Info/Info";
import { Questions } from "../Questions/Questions";
import { Results } from "../Results/Results";
import { SecureRoute } from "../SecureRoute/SecureRoute";
import { SlideControl } from "../SlideControl/SlideControl";
import { Slides } from "../Slides/Slides";
import image from "./HomePageBackground.png";
import { Tools } from "../Tools/Tools";
import { Messages } from "../Messasges/Messages";
import { Notes } from "../Notes/Notes";
import { Generate } from "../Generate/Generate";
import { TimeSheet } from "../TimeSheet/TimeSheet";
import { PDFAnalysis } from "../PDFAnalysis/PDFAnalysis";
import { Graph } from "../Graph/Graph";
import { QRCode } from "../QRCode/QRCode";
import { GamePlanner } from "../QRCode/GamePlanner";
const Experimental = lazy(() => import("../Experimental/Experimental"));

export const Home = () => {
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin rev={true} />;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        id="BackgroundImageBox"
        style={{
          zIndex: "1",
          position: "absolute",
          height: "100%",
          width: "100%",
          backgroundColor: "rgb(10,12,15)",
        }}
      >
        {/* <img
          src={image}
          alt="Background"
          style={{
            height: "100vh",
            width: "100vw",
            overflow: "clip",
          }}
        /> */}
      </div>
      <div
        id="ContentBox"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: "5",
        }}
      >
        <Routes>
          <Route path="/information" element={<Info />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/code" element={<QRCode />} />
          <Route
            path="/tools"
            element={
              <SecureRoute>
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <Tools />
                </Suspense>
              </SecureRoute>
            }
          />
          <Route path="/slides" element={<Slides />} />
          <Route path="/question" element={<Questions />} />
          <Route
            path="/results"
            element={
              <SecureRoute>
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <Results />
                </Suspense>
              </SecureRoute>
            }
          />
          <Route
            path="/slide-controller"
            element={
              <SecureRoute>
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <SlideControl />
                </Suspense>
              </SecureRoute>
            }
          />
          <Route
            path="/experiment"
            element={
              <SecureRoute>
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <Experimental />
                </Suspense>
              </SecureRoute>
            }
          />
          <Route
            path="/messages"
            element={
              <SecureRoute>
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <Messages />
                </Suspense>
              </SecureRoute>
            }
          />
          <Route
            path="/notes"
            element={
              <SecureRoute>
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <Notes />
                </Suspense>
              </SecureRoute>
            }
          />
          <Route
            path="/time"
            element={
              <SecureRoute>
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <TimeSheet />
                </Suspense>
              </SecureRoute>
            }
          />
          <Route
            path="/generate"
            element={
              <SecureRoute>
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <Generate />
                </Suspense>
              </SecureRoute>
            }
          />
          <Route
            path="/pdf"
            element={
              <SecureRoute>
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <PDFAnalysis />
                </Suspense>
              </SecureRoute>
            }
          />
          <Route
            path="/game-editor"
            element={
              <SecureRoute>
                <Suspense fallback={<Spin indicator={antIcon} />}>
                  <GamePlanner />
                </Suspense>
              </SecureRoute>
            }
          />
          <Route
            path="/carbon-dioxide-energy-loss-retention-data-tool"
            element={
              <Suspense fallback={<Spin indicator={antIcon} />}>
                <Graph />
              </Suspense>
            }
          />
          <Route path="/" element={<Dashboard />} />
        </Routes>
      </div>
    </div>
  );
};
