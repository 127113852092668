import { message } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../linker";

type AuthContextType = {
  currentUser: any;
  login: any;
  logout: any;
};

const AuthContext = React.createContext<AuthContextType>(null!);
export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: { children: any }) => {
  const goTo = useNavigate();

  const [currentUser, setCurrentUser] = useState<any>(null);

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password).then(
      () => {
        goTo("/");

        return true;
      },
      (err: any) => {
        message.error("Failed to login... Do you have an account?");
        return null;
      }
    );
  };

  const logout = () => {
    return auth
      .signOut()
      .then(() => {
        goTo("/");
        setCurrentUser(null);
      })
      .catch((err: any) => console.error(err));
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
