import {
  collection,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { useRecoilValue } from "recoil";
import { db } from "../linker";
import { projectState } from "../recoil";
import { Project } from "../types";
import { Button, Calendar, DatePicker } from "antd";

type LogType = {
  hours: number;
  project: string;
  date: number;
  day: string;
  end: number;
};

const colorArray = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

const CustomTooltip = ({
  active,
  payload,
  label,
  projects,
}: {
  active: any;
  payload: any;
  label: any;
  projects: Array<Project> | null;
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: "white", padding: ".5rem" }}
      >
        {" "}
        <p className="intro">
          {new Date(payload[0].payload.date).toDateString()}
        </p>
        {projects && (
          <p>
            {projects
              .filter((val) => val.id === payload[0].payload.project)
              .map((val: any, idx) => {
                const hours = new Date(payload[0].payload[val.id]);
                return (
                  <div>
                    <div key={idx}>{val.title}</div>
                    <p className="label">{`Hours: ${hours.getUTCHours()}:${
                      hours.getMinutes() < 10
                        ? "0" + hours.getMinutes()
                        : hours.getMinutes()
                    }`}</p>
                  </div>
                );
              })}
          </p>
        )}
      </div>
    );
  }

  return null;
};

export const TimeSheet = () => {
  const [data, setData] = useState<Array<LogType>>([]);
  const [week, setWeek] = useState({
    start: new Date().getTime() - 604800000,
    finish: new Date().getTime(),
  });
  const { RangePicker } = DatePicker;
  const projects = useRecoilValue(projectState);

  useEffect(() => {
    const q = query(
      collection(db, "hours"),
      orderBy("timeStart"),
      limitToLast(100)
    );
    const unsub = onSnapshot(q, (snapshot) => {
      setData([]);
      snapshot.forEach((doc) => {
        if (
          doc.data().isEnded &&
          doc.data().timeEnd - doc.data().timeStart > 60000
        ) {
          setData((prev) => [
            ...prev,
            {
              [doc.data().project]: doc.data().timeEnd - doc.data().timeStart, // 3600000, //
              hours: doc.data().timeEnd - doc.data().timeStart, // 3600000, //
              project: doc.data().project,
              date: doc.data().timeStart,
              day: new Date(doc.data().timeStart).toDateString(),
              end: doc.data().timeEnd,
            } as LogType,
          ]);
        }
      });
    });

    return unsub;
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh", backgroundColor: "black" }}>
      {/* <Button onClick={() => console.log({ data })}>Click</Button> */}
      {data && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data
              .sort((a, b) => a.date - b.date)
              .filter((dp) => dp.date > week.start && dp.date < week.finish)}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <Tooltip
              content={
                <CustomTooltip active payload label projects={projects} />
              }
            />
            <XAxis dataKey={"day"} />
            <CartesianGrid stroke="#f5f5f5" />
            <Legend
              verticalAlign="top"
              formatter={(value, entry, idx) => {
                return (
                  <div style={{ color: entry.color, fontSize: ".75rem" }}>
                    {projects
                      ?.filter((pro) => pro.id === value)
                      .map((pro) => pro.title)}
                  </div>
                );
              }}
              //   content={<CustomizedLegend external projects={projects} />}
            />
            {projects &&
              projects.map((pro, idx) => {
                // const paint = `rgb(${Math.random() * Math.pow(10, idx)},${
                //   Math.random() * 200
                // },${Math.random() * 200})`;
                return (
                  <Bar
                    key={idx}
                    dataKey={pro.id}
                    stroke={colorArray[idx]}
                    fill={colorArray[idx]}
                    yAxisId={0}
                    allowReorder="yes"
                    stackId={"day"}
                  />
                );
              })}
          </BarChart>
        </ResponsiveContainer>
      )}
      <RangePicker
        showTime
        onChange={(values) => {
          if (!values) return;

          setWeek({
            start: values[0]?.toDate().getTime()
              ? values[0]?.toDate().getTime()
              : 0,
            finish: values[1]?.toDate().getTime()
              ? values[1]?.toDate().getTime()
              : 0,
          });
        }}
      />
      <div style={{ backgroundColor: "black", height: "100%" }}>
        {data && (
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={projects?.map((pro) => {
                  let value = 0;
                  let name = "";
                  data
                    .filter((dp) => dp.project === pro.id)
                    .filter(
                      (dp) => dp.date > week.start && dp.date < week.finish
                    )
                    .forEach((dp) => {
                      value = value + dp.hours;
                      name = pro.title;
                    });
                  console.log({ value, name });
                  return { value, name: name };
                })}
                dataKey="value"
                nameKey="name"
                legendType="circle"
                label={(value) => {
                  console.log(value);
                  return `${value.name} - ${(value.value / 3600000).toFixed(
                    2
                  )} hrs`;
                }}
              >
                {projects?.map((pro, idx) => {
                  let value = 0;
                  let name = pro.title;
                  data
                    .filter((dp) => dp.project === pro.id)
                    .forEach((dp) => {
                      value = value + dp.hours;
                    });
                  console.log({ value, name });
                  return <Cell key={idx} fill={colorArray[idx]} />;
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {data &&
          data
            .filter((dp) => dp.date > week.start && dp.date < week.finish)
            .map((dp, idx) => {
              const dateNow = new Date().getTime();
              return (
                <div
                  style={{
                    height: "3rem",
                    width: `${(dp.end - dp.date) / 10000}px`,
                    backgroundColor: `rgb(${255 * Math.random()},${
                      255 * Math.random()
                    },${255 * Math.random()})`,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      fontSize: ".5rem",
                    }}
                  >
                    <div>{`${new Date(dp.date).getDate()} - ${new Date(
                      dp.date
                    ).getHours()}:${new Date(dp.date).getMinutes()} `}</div>
                    <div>{`${new Date(dp.end).getDate()} - ${new Date(
                      dp.end
                    ).getHours()}:${new Date(dp.date).getMinutes()}`}</div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};
