import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./App.css";
import { Home } from "./Home/Home";
import { ConfigProvider } from "antd";
import { AuthProvider } from "./AuthProvider/AuthProvider";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: "rgb(100, 180, 255)" },
        components: { Steps: { navArrowColor: "#7FDF0A" } },
      }}
    >
      <RecoilRoot>
        <BrowserRouter>
          <AuthProvider>
            <div
              style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
            >
              <Home />
            </div>
          </AuthProvider>
        </BrowserRouter>
      </RecoilRoot>
    </ConfigProvider>
  );
}

export default App;
